<template>
  <div class="DeliveryRunSheet">
    <div class="DeliveryRunSheet-Create mb-4">
      <h3 class="mb-2">Create DRS</h3>

      <div class="DrsForm">
        <v-container class="ma-0">
          <v-row>
            <v-col cols="4" class="pa-0">
              <v-text-field
                label="Wb Number"
                outlined
                dense
                v-model="wbNumber"
              ></v-text-field>
            </v-col>

            <v-col cols="2" class="pa-0 pl-2">
              <v-btn block depressed color="secondary" @click="getOrder"
                >add</v-btn
              >
            </v-col>

            <v-col cols="2" class="pa-0 pl-2">
              <v-btn block depressed color="warning" @click="createDrs"
                >create drs</v-btn
              >
            </v-col>
          </v-row>

          <v-data-table :headers="headerOrder" :items="itemOrders">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn depressed small color="error" @click="deleteItem(item)">
                <v-icon color="white"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </div>
    </div>

    <div class="DeliveryRunSheet-List">
      <h3 class="mb-2">DRS List</h3>

      <v-data-table
        :headers="headers"
        :items="items"
        :sort-by="sortBy"
        :sort-desc="[true]"
      >
        <!-- <template v-slot:[`item.action`]="{ item }">
          <v-btn depressed small color="error" @click="printDrs(item)">
            print DRS
          </v-btn>
        </template> -->
      </v-data-table>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import moment from "moment";
// import DialogAddDrsComponent from "../../../components/dashboard/operation/DialogAddDrsComponent.vue";

export default {
  name: "DeliveryRunSheet",
  components: {},
  data() {
    return {
      wbNumber: "",
      sortBy: ["date"],
      snackbar: false,
      snackbarText: "",
      headerOrder: [
        { text: "WB Number", value: "wbNumber" },
        { text: "Date", value: "date", filterable: false },
        { text: "Penerima", value: "recipient", filterable: false },
        { text: "Alamat", value: "address", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      itemOrders: [],
      headers: [
        { text: "DRS Id", value: "drsId", filterable: false },
        { text: "Date", value: "date", filterable: false },
        { text: "Total Koli", value: "totalColly", filterable: false },
        { text: "Total Berat", value: "totalWeight", filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    deleteItem(item) {
      const filteredItems = this.items.filter(
        (el) => el.wbNumber != item.wbNumber
      );
      this.itemOrders = filteredItems;
    },
    getOrder() {
      axios({
        url: `${baseUrl}/HubInboundOrder/Find?wbNumber=${this.wbNumber}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const { data } = res.data;
          this.itemOrders.push({
            wbNumber: data.WBNumber,
            date: moment(data.DateCreated)
              // .add(7, "hours")
              .format("YYYY-MM-DD, HH:mm"),
            colly: data.TotalKoli,
            weight: data.TotalBerat,
            status: data.PackageStatus,
            recipient: data.Recipient.Nama,
            address: data.Recipient.Alamat,
          });
          this.wbNumber = "";
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
    createDrs() {
      const hubId = getProfileCookie().KCX_HubIDHub;
      if (this.itemOrders.length === 0) {
        this.snackbar = true;
        this.snackbarText = "Tambah Order terlebih dahulu";
        return;
      }

      axios({
        url: `${baseUrl}/HubDrs`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          idHub: hubId,
          wbNumbers: this.itemOrders.map((el) => {
            return el.wbNumber;
          }),
        },
      })
        .then((res) => {
          const { data } = res;
          this.snackbar = true;
          this.snackbarText = data.message.info[0];
          this.itemOrders = [];
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
    fetchDrs() {
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        url: `${baseUrl}/HubDrs`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredData = res.data.data.filter((el) => el.IDDrs != 0);

          this.items = filteredData.map((drs) => {
            return {
              drsId: drs.IDDrs,
              date: moment(drs.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              totalColly: drs.InboundOrders.reduce((acc, obj) => {
                return acc + obj.TotalKoli;
              }, 0),
              totalWeight: drs.InboundOrders.reduce((acc, obj) => {
                return acc + obj.TotalBerat;
              }, 0),
            };
          });
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
  created() {
    this.fetchDrs();
  },
};
</script>

<style lang="scss" scoped>
.DeliveryRunSheet {
  &-List,
  &-Create {
    background-color: white;
    border-radius: 5px;
    padding: 16px;
  }

  // .DrsForm {
  //   display: flex;
  //   gap: 32px;
  // }
}
</style>
