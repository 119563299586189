<template>
  <div class="DialogAddPackingList">
    <v-dialog v-model="dialogAddDetails" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" depressed v-bind="attrs" v-on="on">
          Add details
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Add Packing List </v-card-title>

        <br />

        <v-card-text>
          <v-container class="ma-0">
            <v-row>
              <v-col sm="4" class="pa-0">
                <v-text-field
                  label="Packing List Id"
                  outlined
                  dense
                  v-model="idPackinglist"
                ></v-text-field>
              </v-col>

              <v-col sm="1" class="pa-0 pl-2">
                <v-btn
                  :loading="isLoading"
                  block
                  depressed
                  @click="addPackinglist"
                  color="secondary"
                  >add</v-btn
                >
              </v-col>
            </v-row>
          </v-container>

          <div>
            <h2 class="mb-2">Packing List</h2>

            <v-data-table :headers="headers" :items="items">
              <template v-slot:[`item.action`]="{ item }">
                <v-btn depressed small color="error" @click="deleteItem(item)">
                  <v-icon color="white"> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card-text>

        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="dialogAddDetails = false">
            cancel
          </v-btn>

          <v-btn
            color="warning"
            class="white--text"
            depressed
            @click="dialogAddDetails = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import moment from "moment";
export default {
  name: "DialogAddPackingList",
  data() {
    return {
      isLoading: false,
      dialogAddDetails: false,
      idPackinglist: "",
      snackbar: false,
      snackbarText: "",
      headers: [
        { text: "Packing Id", value: "packingId", filterable: false },
        { text: "Date", value: "date", filterable: false },
        { text: "Total Koli", value: "totalColly", filterable: false },
        { text: "Total Berat", value: "totalWeight", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    deleteItem(item) {
      const filteredItems = this.items.filter(
        (el) => el.packingId != item.packingId
      );
      this.items = filteredItems;
      this.$emit("deletePackingList", item);
    },
    addPackinglist() {
      this.isLoading = true;

      axios({
        url: `${baseUrl}/PackingList/Find?idPackinglist=${this.idPackinglist}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const { data } = res.data;
          this.idPackinglist = "";
          this.isLoading = false;

          this.items.push({
            packingId: data.IDPackingList,
            date: moment(data.Date).format("YYYY-MM-DD, HH:mm"),
            totalColly: data.TotalKoli,
            totalWeight: data.TotalBerat,
          });

          this.$emit("savePackingList", {
            packingId: data.IDPackingList,
            date: moment(data.Date).format("YYYY-MM-DD, HH:mm"),
            totalColly: data.TotalKoli,
            totalWeight: data.TotalBerat,
          });
        })
        .catch((err) => {
          const { data } = err.response;
          this.idPackinglist = "";
          this.isLoading = false;

          this.snackbarText = data.message.error[0];
          this.snackbar = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Form {
  display: flex;
  gap: 20px;
}
</style>
