<template>
  <div>
    <v-dialog v-model="dialogDeleteVendor" persistent width="600px">
      <v-card>
        <v-card-title> {{ vendor.name }} </v-card-title>

        <v-card-text>
          <h3>{{ vendor.name }}</h3>
          <v-switch v-model="isActive" inset></v-switch>
          <p>{{ vendor.status }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn
            color="warning"
            depressed
            @click="submit"
            :loading="loadingSubmit"
          >
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
export default {
  name: "DialogDeleteVendorComponent",
  props: ["vendor"],
  data() {
    return {
      dialogDeleteVendor: false,
      snackbar: false,
      snackbarText: "",
      loadingSubmit: false,
    };
  },
  computed: {
    isActive: {
      get() {
        return this.vendor.status === "ACTIVE" ? true : false;
      },
      set(val) {
        val
          ? (this.vendor.status = "ACTIVE")
          : (this.vendor.status = "INACTIVE");
      },
    },
  },
  methods: {
    toggleDialog() {
      this.dialogDeleteVendor = !this.dialogDeleteVendor;
    },
    submit() {
      this.loadingSubmit = true;
      axios({
        method: "put",
        url: `${baseUrl}/Transporter/${this.vendor.idTransporter}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          Status: this.vendor.status,
        },
      })
        .then((res) => {
          this.loadingSubmit = false;
          this.toggleDialog();
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.$emit("refresh");
        })
        .catch((err) => {
          this.loadingSubmit = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
