<template>
  <div class="ManagePickup">
    <div class="ManagePickup-Option">
      <v-container class="ma-0">
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              v-model="drsId"
              label="DRS Id"
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="drsId"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
    >
      <template v-slot:top>
        <dialog-assign-driver-component
          :driverType="'delivery'"
          :orderDetail="itemDetail"
          ref="assignDriverDialog"
          @refresh="fetchDrs"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.idStatus < 19"
          depressed
          class="mr-2"
          small
          color="warning"
          @click="assign(item)"
        >
          assign
        </v-btn>

        <v-btn
          v-if="item.idStatus >= 19"
          depressed
          class="mr-2"
          small
          color="warning"
          disabled
        >
          assigned
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import DialogAssignDriverComponent from "../../../components/dashboard/inbound/DialogAssignDriverComponent.vue";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import moment from "moment";

export default {
  components: { DialogAssignDriverComponent },
  name: "ManagePickup",
  data() {
    return {
      isLoading: false,
      itemDetail: {},
      drsId: null,
      sortBy: ["date"],
      headers: [
        { text: "DRS Id", value: "drsId", filterable: false },
        { text: "Date", value: "date", filterable: false },
        { text: "Total Koli", value: "totalColly", filterable: false },
        { text: "Total Berat", value: "totalWeight", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewDriverDialog.toggleDialog();
    },
    assign(item) {
      this.itemDetail = item;
      this.$refs.assignDriverDialog.toggleDialog();
    },
    fetchDrs() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        method: "get",
        url: `${baseUrl}/HubDrs`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredData = res.data.data.filter((el) => el.IDDrs != 0);

          this.items = filteredData.map((drs) => {
            return {
              drsId: drs.IDDrs,
              date: moment(drs.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              totalColly: drs.InboundOrders.reduce((acc, obj) => {
                return acc + obj.TotalKoli;
              }, 0),
              totalWeight: drs.InboundOrders.reduce((acc, obj) => {
                return acc + obj.TotalBerat;
              }, 0),
              idStatus: drs.InboundOrders[0]
                ? drs.InboundOrders[0].PackageStatusIDPackageStatus
                : null,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchDrs();
  },
};
</script>

<style lang="scss" scoped>
.ManagePickup {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
