<template>
  <div class="JobOrder">
    <div class="JobOrder-Option">
      <v-container class="ma-0">
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              v-model="orderId"
              label="Order Id"
              outlined
              dense
              type="number"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="orderId"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          v-if="item.idStatus == 2"
          small
          color="primary"
          @click="acceptOrder(item)"
        >
          accept order
        </v-btn>

        <v-btn
          depressed
          v-else-if="item.idStatus == 3"
          small
          color="info"
          @click="confirmShipper(item)"
        >
          confirm shipper
        </v-btn>

        <v-btn
          depressed
          v-else-if="item.idStatus == 4"
          small
          color="warning"
          @click="assignDriver"
        >
          pilih driver
        </v-btn>

        <v-btn
          depressed
          v-else-if="item.idStatus == 5 || item.idStatus == 6"
          small
          color="warning"
          disabled
        >
          driver assigned
        </v-btn>

        <v-btn
          depressed
          v-else-if="item.idStatus == 7"
          small
          color="success"
          @click="createOrder(item)"
        >
          create Order
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { formatToRupiah } from "../../../utils/formatter";

export default {
  name: "JobOrder",
  data() {
    return {
      orderId: "",
      isLoading: false,
      isButtonLoading: false,
      snackbar: false,
      snackbarText: "",
      sortBy: [
        "dateCreated",
        "shipperName",
        "shipperCity",
        "recipientName",
        "recipientCity",
        "colly",
        "weight",
        "price",
        "status",
      ],
      headers: [
        { text: "Order Id", value: "idOrder" },
        { text: "Date", value: "dateCreated", filterable: false },
        { text: "Pengirim", value: "shipperName", filterable: false },
        { text: "Telp", value: "shipperPhone", filterable: false },
        { text: "Origin", value: "shipperCity" },
        { text: "Penerima", value: "recipientName", filterable: false },
        { text: "Destination", value: "recipientCity", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Harga", value: "price", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    assignDriver() {
      this.$router.push("/dashboard/manage-pickup");
    },
    createOrder(item) {
      this.$store.commit("order/SET_WALKIN", {
        isWalkin: false,
        idOrder: item.idOrder,
        idShipper: item.idShipper,
        idRecipient: item.idRecipient,
        idCollies: item.items.map((el) => el.IDKoli),
      });

      this.$store.commit("order/SET_SHIPPER", {
        shipperName: item.shipperName,
        shipperPhone: item.shipperPhone,
        shipperEmail: item.shipperEmail,
        shipperCity: item.shipperCity,
        shipperCityId: item.shipperIdCity,
        shipperKelurahan: item.shipperVillage,
        shipperKecamatanId: item.shipperIdDistrict,
        shipperKelurahanId: item.shipperIdVillage,
        shipperPostCode: item.shipperPostCode,
        shipperAddress: item.shipperAddress,
      });

      this.$store.commit("order/SET_RECIPIENT", {
        recipientName: item.recipientName,
        recipientPhone: item.recipientPhone,
        recipientEmail: item.recipientEmail,
        recipientCity: item.recipientCity,
        recipientCityId: item.recipientIdCity,
        recipientKelurahan: item.recipientVillage,
        recipientKecamatanId: "",
        recipientKelurahanId: item.recipientIdVillage,
        recipientPostCode: item.recipientPostCode,
        recipientAddress: item.recipientAddress,
      });

      this.$store.commit("order/SET_ITEM", {
        moda: "Darat",
        description: item.description,
        instruction: item.instruction,
        discount: item.discount,
        tax: item.discount,
        kargoPrice: item.totalPrice,
        totalWeight: item.weight,
        estimateValue: item.estimateValue,
        insurance: 0,
        collies: item.items.map((el) => {
          return {
            numberColly: el.JumlahKoli,
            weight: el.Berat,
            length: el.Panjang,
            width: el.Lebar,
            height: el.Tinggi,
            volumeWeight: el.BeratVolume,
            isPacking: el.IsPacking,
            packingPrice: 0,
            mostWeight: el.Terberat,
            totalPrice: el.TotalBiaya,
          };
        }),
      });

      this.$router.push("/dashboard/create-order");
    },
    acceptOrder(item) {
      this.updateOrder(item.idOrder, 3);
    },
    confirmShipper(item) {
      this.updateOrder(item.idOrder, 4);
    },
    updateOrder(idOrder, idStatus) {
      axios({
        method: "put",
        url: `${baseUrl}/HubOrders/${idOrder}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: idStatus,
        },
      })
        .then((res) => {
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.fetchOrder();
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
    fetchOrder() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        method: "get",
        url: `${baseUrl}/HubOrders`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "2,3,4,5,6,7",
          hub: hubId,
        },
      })
        .then((res) => {
          this.items = res.data.data.map((order) => {
            return {
              idOrder: order.IDOrder,
              idShipper: order.ShipperLoc.IDShipperLoc,
              idRecipient: order.Recipient.IDRecipient,
              dateCreated: moment(order.DateCreated)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              shipperName: order.ShipperLoc.Nama,
              shipperPhone: order.ShipperLoc.Telp,
              shipperEmail: order.ShipperLoc.Email,
              shipperCity: order.ShipperLoc.NamaCity,
              shipperIdCity: order.ShipperLoc.IDCity,
              shipperVillage: order.ShipperLoc.NamaKelurahan,
              shipperIdVillage: order.ShipperLoc.IDKelurahan,
              shipperIdDistrict: order.ShipperLoc.IDKecamatan,
              shipperAddress: order.ShipperLoc.Alamat,
              shipperPostCode: order.ShipperLoc.KodePos,
              recipientName: order.Recipient.Nama,
              recipientPhone: order.Recipient.Telp,
              recipientEmail: order.Recipient.Email,
              recipientCity: order.Recipient.City.NamaCity,
              recipientIdCity: order.Recipient.CityIDCity,
              recipientVillage: order.Recipient.SubDistrict.NamaKelurahan,
              recipientIdVillage: order.Recipient.KelurahanIDKelurahan,
              recipientPostCode: order.Recipient.KelurahanKodePos,
              recipientAddress: order.Recipient.Alamat,
              description: order.Keterangan,
              instruction: order.Instruction,
              items: order.Collies,
              colly: order.TotalKoli,
              weight: order.TotalBerat,
              estimateValue: order.EstimasiNilai,
              status: order.PackageStatus.Status,
              idStatus: order.PackageStatus.IDPackageStatus,
              totalPrice: order.TotalBiaya,
              discount: order.Diskon,
              tax: order.PPN,
              price: formatToRupiah(order.TotalBiaya),
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchOrder();
  },
};
</script>

<style lang="scss" scoped>
.JobOrder {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
