<template>
  <div class="DialogSendToJneComponent">
    <v-dialog v-model="dialogSendJne" persistent max-width="600px">
      <v-card>
        <v-card-title> Assign JNE </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dataStore.datePickup"
                      label="Date Pickup"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataStore.datePickup"
                    no-title
                    scrollable
                    @input="menuDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="dataStore.timePickup"
                  label="Time Pickup"
                  prepend-icon="mdi-clock-time-four-outline"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              label="Branch"
              type="text"
              :rules="requiredRule"
              outlined
              dense
              v-model="dataStore.branch"
            ></v-text-field>

            <v-select
              outlined
              dense
              label="Pickup Vehicle"
              :rules="requiredRule"
              item-text="text"
              item-value="value"
              :items="['MOBIL', 'MOTOR']"
              v-model="dataStore.pickupVehicle"
            ></v-select>

            <v-select
              outlined
              dense
              label="Type Pickup"
              :rules="requiredRule"
              item-text="text"
              item-value="value"
              :items="['PICKUP', 'DROP']"
              v-model="dataStore.type"
            ></v-select>

            <v-text-field
              label="Origin"
              type="text"
              :rules="requiredRule"
              outlined
              dense
              v-model="dataStore.originCode"
            ></v-text-field>

            <!-- <v-text-field
              label="Destination"
              type="text"
              :rules="requiredRule"
              outlined
              dense
              v-model="dataStore.destinationCode"
            >
            </v-text-field> -->
          </v-form>
          <!-- <v-chip
            small
            class="ma-2"
            color="primary"
            outlined
            @click="openKodepos()"
          >
            <v-icon left>mdi-email</v-icon>
            Cari Kodepos
          </v-chip> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn color="warning" depressed @click="assignJne"> Assign </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogKodepos" persistent>
      <v-card>
        <v-card-title> Kodespos JTR </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="Branch"
              type="number"
              outlined
              dense
              :value="internalValue"
              @input="updateInternalValue"
              :autofocus="true"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="listKodepos"
          :sort-by="sortBy"
          :loading="isLoading"
          :sort-desc="[true]"
          :single-expand="true"
          show-expand
          @click:row="choiceOrigin"
        >
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="dialogKodepos = false">
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import _debounce from "lodash/debounce";

export default {
  name: "DialogSendToJneComponent",
  props: ["detailItem", "fetchManifests"],
  data() {
    return {
      dialogSendJne: false,
      dialogKodepos: false,
      valid: false,
      snackbar: false,
      snackbarText: "",
      requiredRule: [(value) => !!value || "Required"],
      dataStore: {
        originCode: "",
        destinationCode: "",
        type: "PICKUP",
        branch: "CGK000",
        pickupVehicle: "MOBIL",
        items: "",
        datePickup: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        timePickup: null,
      },
      date: "",
      listKodepos: [],
      internalValue: this.value,
      touched: false,
      sortBy: [
        "origin",
        "destination",
        "nama_kota",
        "kecamatan",
        "kelurahan",
        "kodepos",
      ],
      headers: [
        { text: "Origin Code", value: "origin" },
        { text: "Destionation Code", value: "destination" },
        { text: "Kota", value: "nama_kota" },
        { text: "Kecamatan", value: "kecamatan" },
        { text: "Keluarahan", value: "kelurahan" },
        { text: "Kodepos", value: "kodepos" },
      ],
      isLoading: false,
      menuDate: false,
      timeDate: false,
    };
  },
  watch: {
    value(value) {
      if (!this.touched) this.internalValue = value;
    },
  },
  methods: {
    toggleDialog() {
      this.dialogSendJne = !this.dialogSendJne;
    },
    setTime() {
      this.dataStore.datePickup = this.detailItem.tPickup[0];
      this.dataStore.timePickup = this.detailItem.tPickup[1];
    },
    assignJne() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.dataStore.items = this.detailItem;
        // console.log(this.dataStore);
        axios({
          method: "put",
          url: `${baseUrl}/AssignJne/${this.detailItem.manifestId}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: this.dataStore,
        })
          .then(() => {
            this.snackbar = true;
            this.snackbarText = "success";
            this.toggleDialog();
            this.fetchManifests();
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = "error";
            this.toggleDialog();
            this.fetchManifests();
          });
      }
    },
    openKodepos() {
      this.dialogKodepos = true;
    },
    async getKodepos(city) {
      try {
        this.isLoading = false;
        this.dataStore.branch = city.CitySNI + "000";
        this.dataStore.originCode = city.CitySNI + "10000";
      } catch (error) {
        // console.log(error);
        this.snackbar = true;
        this.snackbarText = "error";
      }
    },
    updateInternalValue(event) {
      this.touched = true;
      this.updateValue(event);
    },
    updateValue: _debounce(function (value) {
      this.touched = false;
      this.isLoading = true;
      if (isNaN(value)) {
        this.snackbarText = "Pastikan Isian Anda Benar";
        this.isLoading = false;
      } else {
        this.getKodepos(value);
      }
    }, 600),
    choiceOrigin(row) {
      this.dataStore.destinationCode = row.destination;
      this.dialogKodepos = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
