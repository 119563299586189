<template>
  <div class="AreaManagement">
    <div class="AreaManagement-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-select
              label="Select Area"
              :items="options"
              v-model="optionArea"
              item-text="text"
              item-value="value"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :sort-by="sortBy"
      :loading="isLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <dialog-status-area-component
          :area="itemDetail"
          ref="statusAreaDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn class="mr-2" small color="green" @click="view(item)" depressed>
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogStatusAreaComponent from "../../../components/dashboard/settings/DialogStatusAreaComponent.vue";
import { baseUrl } from "../../../constant/url";
import axios from "axios";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "AreaManagement",
  components: { DialogStatusAreaComponent },
  data() {
    return {
      optionArea: null,
      isLoading: false,
      itemDetail: {},
      options: [
        { text: "Provinsi", value: "province" },
        { text: "Kota/Kabupaten", value: "city" },
        { text: "Kecamatan", value: "district" },
        { text: "Kelurahan", value: "village" },
      ],
      sortBy: ["id", "code", "active"],
      headers: [],
      items: [],
    };
  },
  methods: {
    view(item) {
      if (this.optionArea === "province") {
        this.itemDetail = {
          area: "province",
          id: item.id,
          name: item.province,
          active: item.active,
        };
      }

      if (this.optionArea === "city") {
        this.itemDetail = {
          area: "city",
          id: item.id,
          name: item.city,
          active: item.active,
        };
      }

      if (this.optionArea === "district") {
        this.itemDetail = {
          area: "kecamatan",
          id: item.id,
          name: item.district,
          active: item.active,
        };
      }

      if (this.optionArea === "village") {
        this.itemDetail = {
          area: "kelurahan",
          id: item.id,
          name: item.village,
          active: item.active,
        };
      }

      this.$refs.statusAreaDialog.toggleDialog();
    },
    fetchProvince() {
      this.isLoading = true;
      this.items = [];
      axios({
        method: "get",
        url: `${baseUrl}/Provinsi`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.items = res.data.data.map((province) => {
            return {
              id: province.IDProp,
              code: province.KodeProvinsi,
              province: province.NamaProvinsi,
              active: province.IsActive,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
    fetchCity() {
      this.isLoading = true;
      this.items = [];
      axios({
        method: "get",
        url: `${baseUrl}/City`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.items = res.data.data.map((city) => {
            return {
              id: city.IDCity,
              code: city.KodeCity,
              citySNI: city.CitySNI,
              city: city.NamaCity,
              active: city.IsActive,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
    fetchDistrict() {
      this.isLoading = true;
      this.items = [];
      axios({
        method: "get",
        url: `${baseUrl}/Kecamatan`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.items = res.data.data.map((district) => {
            return {
              id: district.IDKecamatan,
              code: district.KodeKecamatan,
              district: district.NamaKecamatan,
              active: district.IsActive,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
    fetchVillage() {
      this.isLoading = true;
      this.items = [];
      axios({
        method: "get",
        url: `${baseUrl}/Kelurahan`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.items = res.data.data.map((village) => {
            return {
              id: village.IDKelurahan,
              postCode: village.KodePos,
              village: village.NamaKelurahan,
              zona: village.Zona,
              active: village.IsActive,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  watch: {
    optionArea: function (val) {
      if (val === "province") {
        this.headers = [
          { text: "Id", value: "id" },
          { text: "Kode", value: "code" },
          { text: "Provinsi", value: "province" },
          { text: "Active", value: "active" },
          { text: "Action", value: "action", sortable: false },
        ];

        this.fetchProvince();
      }

      if (val === "city") {
        this.headers = [
          { text: "Id", value: "id" },
          { text: "Kode", value: "code" },
          { text: "Kode SNI", value: "citySNI" },
          { text: "Kota", value: "city" },
          { text: "Active", value: "active" },
          { text: "Action", value: "action", sortable: false },
        ];

        this.fetchCity();
      }

      if (val === "district") {
        this.headers = [
          { text: "Id", value: "id" },
          { text: "Kode", value: "code" },
          { text: "Kecamatan", value: "district" },
          { text: "Active", value: "active" },
          { text: "Action", value: "action", sortable: false },
        ];

        this.fetchDistrict();
      }

      if (val === "village") {
        this.headers = [
          { text: "Id", value: "id" },
          { text: "Kode", value: "postCode" },
          { text: "Kelurahan", value: "village" },
          { text: "Zona", value: "zona" },
          { text: "Active", value: "active" },
          { text: "Action", value: "action", sortable: false },
        ];

        this.fetchVillage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.AreaManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
