<template>
  <div class="PackingList">
    <div class="PackingList-Option">
      <v-container class="ma-0">
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              label="Search City"
              outlined
              dense
              v-model="city"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="city"
      :sort-by="sortBy"
      :sort-desc="[true]"
      :loading="isLoading"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="success" @click="view(item)">
          detail
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import moment from "moment";
export default {
  name: "PackingList",
  data() {
    return {
      sortBy: [
        // "packingId",
        "date",
        "destination",
        "totalColly",
        "totalWeight",
        // "status",
      ],
      isLoading: false,
      city: "",
      headers: [
        {
          text: "Packing Id",
          value: "packingId",
          filterable: false,
        },
        { text: "Date", value: "date", filterable: false },
        { text: "Tujuan", value: "destination" },
        { text: "Total Koli", value: "totalColly", filterable: false },
        { text: "Total Berat", value: "totalWeight", filterable: false },
        // { text: "Status", value: "status", filterable: false },
        // { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    view(item) {
      console.log(item);
    },
    fetchPackingList() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        method: "get",
        url: `${baseUrl}/PackingList`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredData = res.data.data.filter(
            (el) => el.IDPackingList != 0
          );

          this.items = filteredData.map((packingList) => {
            return {
              packingId: packingList.IDPackingList,
              date: moment(packingList.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              destination: packingList.KotaTujuan,
              totalColly: packingList.TotalKoli,
              totalWeight: packingList.TotalBerat,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchPackingList();
  },
};
</script>

<style lang="scss" scoped>
.PackingList {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
