<template>
  <div class="ManageManifest">
    <div class="ManageManifest-Create mb-4">
      <h3 class="mb-2">Create Manifest</h3>
      <v-form ref="form" v-model="valid">
        <div class="ManageManifest-Container">
          <v-container>
            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Kota Tujuan</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-combobox
                  outlined
                  dense
                  :rules="rules"
                  :items="cityList"
                  :value="cityDestination"
                  @input="setCityDestination"
                ></v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Kota Asal</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-combobox
                  outlined
                  dense
                  :rules="rules"
                  :items="cityList"
                  :value="cityOrigin"
                  @input="setCityOrigin"
                ></v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Moda</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-select
                  outlined
                  dense
                  disabled
                  :items="listModa"
                  v-model="moda"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Keterangan</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-textarea
                  outlined
                  rows="2"
                  no-resize
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="4" class="pa-0">
                <h4>Total Koli</h4>
              </v-col>

              <v-col cols="8" class="pa-0">
                <v-text-field outlined dense disabled v-model="totalColly">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Total Berat</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="totalWeight"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Hub Destination</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-select
                  outlined
                  dense
                  label="Hub Destination"
                  :items="hubList"
                  v-model="idHub"
                  item-text="NamaHub"
                  :rules="rules"
                  item-value="IDHub"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8" class="pa-0 d-flex">
                <dialog-add-packing-list-component
                  @savePackingList="savePackingList"
                  @deletePackingList="deletePackingList"
                />

                <v-btn
                  @click="createManifest"
                  :loading="isRequestLoading"
                  color="warning"
                  depressed
                  class="ml-2 white--text"
                >
                  request
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-form>
    </div>

    <div class="ManageManifest-List">
      <h3 class="mb-2">Manifest List</h3>

      <v-data-table
        :headers="headers"
        :items="items"
        :sort-by="sortBy"
        :loading="isLoading"
        :sort-desc="[true]"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            depressed
            class="mr-2"
            small
            color="warning"
            @click="print(item)"
          >
            print
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import DialogAddPackingListComponent from "../../../components/dashboard/operation/DialogAddPackingListComponent.vue";

import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import cityData from "../../../data/city.json";

export default {
  name: "ManageManifest",
  components: { DialogAddPackingListComponent },
  data() {
    return {
      valid: false,
      rules: [(value) => !!value || "Required"],
      sortBy: ["date", "destination", "totalColly", "totalWeight", "status"],
      moda: 1,
      listModa: [
        { text: "Darat", value: 1 },
        { text: "Laut", value: 2 },
        { text: "Udara", value: 3 },
      ],
      cityDestination: "",
      idCityDestination: "",
      cityOrigin: "",
      idCityOrigin: "",
      listOrders: [],
      hubList: [],
      idHub: "",
      description: "",
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      isRequestLoading: false,
      headers: [
        { text: "No Manifest", value: "manifestId" },
        { text: "Date", value: "date" },
        { text: "Tujuan", value: "destination" },
        { text: "Total Koli", value: "totalColly" },
        { text: "Total Berat", value: "totalWeight" },
        { text: "Keterangan", value: "description" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      items: [],
    };
  },
  computed: {
    totalColly() {
      if (this.listOrders.length === 0) {
        return 0;
      }

      return this.listOrders.reduce((acc, obj) => {
        return acc + obj.totalColly;
      }, 0);
    },
    totalWeight() {
      if (this.listOrders.length === 0) {
        return 0;
      }

      return this.listOrders.reduce((acc, obj) => {
        return acc + obj.totalWeight;
      }, 0);
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
  },
  methods: {
    print(item) {
      let routeData = this.$router.resolve(
        `/document/Manifest?manifestId=${item.manifestId}`
      );
      window.open(routeData.href, "_blank");
    },
    setCityDestination(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.cityDestination = cityName;
        this.idCityDestination = city.idCity;
      }
    },
    setCityOrigin(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.cityOrigin = cityName;
        this.idCityOrigin = city.idCity;
      }
    },
    savePackingList(e) {
      this.listOrders.push(e);
    },
    deletePackingList(e) {
      const filteredItems = this.listOrders.filter(
        (el) => el.packingId != e.packingId
      );
      this.listOrders = filteredItems;
    },
    fetchManifest() {
      const hubId = getProfileCookie().KCX_HubIDHub;

      this.isLoading = true;

      axios({
        url: `${baseUrl}/HubManifest`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredData = res.data.data.filter((el) => el.IDManifest != 0);

          this.items = filteredData.map((el) => {
            return {
              manifestId: el.IDManifest,
              date: moment(el.Date).format("YYYY-MM-DD, HH:mm"),
              destination: el.KotaTujuan,
              totalColly: el.TotalKoli,
              totalWeight: el.TotalBerat,
              description: el.Keterangan,
              status: el.Status,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    fetchHub() {
      axios({
        method: "get",
        url: `${baseUrl}/Hub`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const hubData = res.data.data.filter(
            (el) => el.IDHub != 0 && el.Status == "ACTIVE"
          );

          this.hubList = hubData;
        })
        .catch(() => {
          this.hubList = [];
        });
    },
    createManifest() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.isRequestLoading = true;
        axios({
          url: `${baseUrl}/HubManifest`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            Date: new Date(),
            TotalKoli: this.totalColly,
            TotalBerat: this.totalWeight,
            Keterangan: this.description,
            KotaAsal: this.cityOrigin,
            KotaTujuan: this.cityDestination,
            KCX_HubIDHub: getProfileCookie().KCX_HubIDHub,
            KCX_ViaIDVia: this.moda,
            IDHubTujuan: this.idHub,
            PackingList:
              this.listOrders.length === 0
                ? []
                : this.listOrders.map((el) => {
                    return el.packingId;
                  }),
          },
        })
          .then((res) => {
            this.isRequestLoading = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.fetchManifest();
          })
          .catch((err) => {
            this.isRequestLoading = true;
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
  },
  created() {
    this.fetchManifest();
    this.fetchHub();
  },
};
</script>

<style lang="scss" scoped>
.ManageManifest {
  &-Create {
    background-color: white;
    border-radius: 5px;
    padding: 16px;
  }

  &-List {
    background-color: white;
    border-radius: 5px;
    padding: 16px;
  }

  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
