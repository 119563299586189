<template>
  <div class="OrderConfirmation">
    <div class="OrderConfirmation-Option mb-10">
      <v-container class="ma-0">
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              v-model="idOrder"
              label="Order Id or WB Number"
              type="string"
              clearable
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col sm="3" class="pa-0 pl-4">
            <v-select
              class="mr-2"
              outlined
              dense
              label="Hub"
              item-text="text"
              item-value="value"
              :items="hubList"
              v-model="hub"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="4" class="pa-0 mr-2">
            <v-btn depressed color="warning" class="mr-2" @click="search"
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
    >
      <template v-slot:top>
        <dialog-confirm-order-component
          :order="itemDetail"
          @refresh="fetchOrder()"
          ref="confirmOrderDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="warning" @click="view(item)">
          detail
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogConfirmOrderComponent from "../../../components/dashboard/inbound/DialogConfirmOrderComponent.vue";

import axios from "axios";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import moment from "moment";
import { formatToRupiah } from "../../../utils/formatter";

export default {
  components: { DialogConfirmOrderComponent },
  name: "OrderConfirmation",
  data() {
    return {
      idOrder: "",
      itemDetail: {},
      sortBy: [
        "date",
        "shipper",
        "recipient",
        "colly",
        "weight",
        "price",
        "status",
      ],
      headers: [
        { text: "Order Id", value: "idOrder" },
        { text: "WB Number", value: "wbNumber", filterable: false },
        { text: "Date", value: "date" },
        { text: "Pengirim", value: "shipper", filterable: false },
        { text: "Penerima", value: "recipient", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Harga", value: "price", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      hubList: [],
      hub: "",
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.confirmOrderDialog.toggleDialog();
    },
    fetchOrder() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;
      let queryParams = ``;
      if (this.idOrder.length > 0 || this.idOrder !== null)
        queryParams = `orderId=${this.idOrder}`;
      if (this.hub !== "") queryParams = `${queryParams}&hub=${this.hub}`;

      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders?${queryParams}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "8,9,10,11,12,13,14,15,16,17,18,19,20,21,22",
          hub: hubId,
        },
      })
        .then((res) => {
          this.items = res.data.data.map((order) => {
            return {
              idOrder: order.OrderIDOrder,
              wbNumber: order.WBNumber,
              date: moment(order.DateCreated)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              origin: order.Order.ShipperLoc.NamaCity,
              shipper: order.Order.ShipperLoc.Nama,
              phone: order.Order.ShipperLoc.Telp,
              recipient: order.Order.Recipient.Nama,
              destination: order.Order.Recipient.City.NamaCity,
              colly: order.TotalKoli,
              weight: order.TotalBerat,
              status: order.PackageStatus.Status,
              idStatus: order.PackageStatus.IDPackageStatus,
              price: formatToRupiah(order.TotalBiaya),
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
    fetchHub() {
      axios({
        method: "get",
        url: `${baseUrl}/Hub`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDHub != 0);

          this.hubList = filteredItem.map((hub) => {
            return {
              value: hub.IDHub,
              text: hub.NamaHub,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    search() {
      this.fetchOrder();
    },
  },
  created() {
    this.fetchOrder();
    this.fetchHub();
  },
};
</script>

<style lang="scss" scoped>
.OrderConfirmation {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
