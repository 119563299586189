<template>
  <div class="OrderManagement">
    <div class="OrderManagement-Option">
      <v-container class="ma-0">
        <v-row>
          <!-- <v-col sm="4" class="pa-0">
            <v-text-field
              v-model="date"
              type="date"
              outlined
              label="Date"
              dense
            ></v-text-field>
          </v-col> -->
          <v-col sm="4" class="pa-0 mr-2">
            <v-menu
              v-model="startDtMenu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="formatDate(dateFrom)"
                  label="Date From"
                  dense
                  outlined
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateFrom"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="4" class="pa-0">
            <v-menu
              v-model="startDtMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="formatDate(date)"
                  label="Date To"
                  dense
                  outlined
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col sm="2" class="pa-0">
            <v-select
              class="mr-2"
              outlined
              dense
              label="Origin"
              item-text="text"
              item-value="value"
              :items="originList"
              v-model="origin"
            ></v-select>
          </v-col>

          <v-col sm="2" class="pa-0">
            <v-select
              outlined
              dense
              label="Destination"
              item-text="text"
              item-value="value"
              :items="destinationList"
              v-model="destination"
            ></v-select>
          </v-col>
        </v-row> -->

        <!-- <v-row>
          <v-col sm="4" class="pa-0">
            <v-select
              outlined
              dense
              label="Status"
              item-text="text"
              item-value="value"
              :items="statusList"
              v-model="status"
            ></v-select>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-select
              class="mr-2"
              outlined
              dense
              label="Hub"
              item-text="text"
              item-value="value"
              :items="hubList"
              v-model="hub"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="4" class="pa-0 mr-2">
            <v-btn depressed color="warning" class="mr-2" @click="search"
              >Search</v-btn
            >

            <v-btn depressed color="error" @click="refresh">refresh</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
    >
      <template v-slot:top>
        <dialog-assign-order-component
          @refresh="fetchOrder"
          :order="itemDetail"
          ref="assignOrderDialog"
        />
      </template>

      <!-- TODO: hide this button for now -->
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          class="mr-2"
          small
          color="success"
          @click="assign(item)"
          v-if="item.status == 'ORDER_CREATED'"
        >
          assign
        </v-btn>

        <v-btn v-else depressed class="mr-2" small color="success" disabled>
          assign
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAssignOrderComponent from "../../../components/dashboard/inbound/DialogAssignOrderComponent.vue";

import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { formatToRupiah } from "../../../utils/formatter";

export default {
  components: { DialogAssignOrderComponent },
  name: "OrderManagement",
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      dateFrom: moment().subtract(1, "months").format("YYYY-MM-DD"),
      origin: "",
      destination: "",
      originList: [],
      destinationList: [],
      status: "",
      statusList: [],
      isLoading: false,
      itemDetail: {},
      sortBy: [
        "dateCreated",
        "origin",
        "destination",
        "status",
        "colly",
        "weight",
        "hub",
        "price",
      ],
      headers: [
        { text: "Order Id", value: "idOrder", filterable: false },
        { text: "Date", value: "dateCreated" },
        { text: "Origin", value: "origin", filterable: false },
        { text: "Destination", value: "destination", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Harga", value: "price", filterable: false },
        { text: "Hub", value: "hub", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      filteredItems: [],
      hubList: [],
      hub: "",
    };
  },
  methods: {
    search() {
      if (this.date) {
        this.filterDate();
      }

      if (this.origin) {
        this.filterOrigin();
      }

      if (this.destination) {
        this.filterDestination();
      }

      if (this.status) {
        this.filterStatus();
      }
      this.fetchOrder();
    },
    refresh() {
      this.filteredItems = this.items;
      this.date = "";
      this.destination = "";
      this.origin = "";
      this.status = "";
    },
    generateList(data) {
      this.statusList = [...new Set(data.map((item) => item.status))];
      this.originList = [...new Set(data.map((item) => item.origin))];
      this.destinationList = [...new Set(data.map((item) => item.destination))];
    },
    filterOrigin() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.origin == this.origin;
      });
    },
    filterDestination() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.destination == this.destination;
      });
    },
    filterStatus() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.status == this.status;
      });
    },
    filterDate() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return (
          moment(el.date).format("YYYY-MM-DD") ==
          moment(this.date).format("YYYY-MM-DD")
        );
      });
    },
    assign(item) {
      this.itemDetail = item;
      this.$refs.assignOrderDialog.toggleDialog();
    },
    fetchOrder() {
      this.isLoading = true;
      let keyword = "";

      if (this.date !== "") keyword = `dateTo=${this.date}`;
      if (this.dateFrom !== "")
        keyword = `${keyword}&dateFrom=${this.dateFrom}`;
      if (this.hub !== "") keyword = `${keyword}&hub=${this.hub}`;

      axios({
        method: "get",
        url: `${baseUrl}/Orders?${keyword}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "1,2,3",
        },
      })
        .then((res) => {
          const items = res.data.data.map((order) => {
            return {
              idOrder: order.IDOrder,
              dateCreated: moment(order.DateCreated)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              // dateCreated: new Date(order.DateCreated).toLocaleString(),
              origin: order.ShipperLoc.NamaCity,
              shipper: order.ShipperLoc.Nama,
              recipient: order.Recipient.Nama,
              destination: order.Recipient
                ? order.Recipient.City.NamaCity
                : null,
              colly: order.TotalKoli,
              weight: order.TotalBerat,
              hub: order.Hub ? order.Hub.NamaHub : null,
              status: order.PackageStatus.Status,
              price: formatToRupiah(order.TotalBiaya),
            };
          });

          this.items = items;
          this.filteredItems = items;
          this.generateList(items);
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.filteredItems = [];
          this.isLoading = false;
        });
    },
    fetchHub() {
      axios({
        method: "get",
        url: `${baseUrl}/Hub`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDHub != 0);

          this.hubList = filteredItem.map((hub) => {
            return {
              value: hub.IDHub,
              text: hub.NamaHub,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    formatDate(start) {
      if (start === "") return "";
      return moment(start).format("DD/MM/YYYY");
    },
  },
  created() {
    this.fetchOrder();
    this.fetchHub();
  },
};
</script>

<style lang="scss" scoped>
.OrderManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
