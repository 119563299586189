<template>
  <div class="DialogAssignOrderComponent">
    <v-dialog v-model="dialogAssignOrder" persistent max-width="600px">
      <v-card>
        <v-card-title> Assign Order to Hub </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Order Id</p>
                <p class="ma-0">
                  <strong>{{ order.idOrder }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Date</p>
                <p class="ma-0">
                  <strong>{{ order.dateCreated }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Origin</p>
                <p class="ma-0">
                  <strong>{{ order.origin }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Destintation</p>
                <p class="ma-0">
                  <strong>{{ order.destination }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Status</p>
                <p class="ma-0">
                  <strong>{{ order.status }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>
          </v-container>

          <br />

          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-select
                    outlined
                    dense
                    label="Hub Branch"
                    :items="hubList"
                    v-model="idHub"
                    item-text="NamaHub"
                    :rules="requiredRule"
                    item-value="IDHub"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn
            color="warning"
            :loading="isLoading"
            depressed
            @click="assignHub"
          >
            assign hub
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogAssignOrderComponent",
  props: ["order"],
  data() {
    return {
      isLoading: false,
      dialogAssignOrder: false,
      hubList: [],
      idHub: "",
      valid: false,
      snackbar: false,
      snackbarText: "",
      requiredRule: [(value) => !!value || "Required"],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogAssignOrder = !this.dialogAssignOrder;
    },
    assignHub() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.isLoading = true;
        axios({
          method: "put",
          url: `${baseUrl}/Orders/${this.order.idOrder}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            status: "2",
          },
          data: {
            KCX_HubIDHub: this.idHub,
          },
        })
          .then((res) => {
            this.isLoading = false;
            this.dialogAssignOrder = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
  },
  created() {
    axios({
      method: "get",
      url: `${baseUrl}/Hub`,
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    })
      .then((res) => {
        const hubData = res.data.data.filter(
          (el) => el.IDHub != 0 && el.Status == "ACTIVE"
        );

        this.hubList = hubData;
      })
      .catch(() => {
        this.hubList = [];
      });
  },
};
</script>

<style lang="scss" scoped></style>
