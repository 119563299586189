export const userType = {
  supervisorHub: "SUPERVISOR_HUB",
  dataEntry: "DATA_ENTRY_HUB",
  operationHub: "OPERATION_HUB",
  managerHo: "MANAGER_HO",
  superAdminHo: "SUPER_ADMIN_HO",
  operationHo: "OPERATION_HO",
  driver: "DRIVER",
};

export const userTypeList = [
  {
    value: 1,
    text: "MANAGER_HO",
  },
  {
    value: 2,
    text: "SUPER_ADMIN_HO",
  },
  {
    value: 3,
    text: "OPERATION_HO",
  },
  {
    value: 4,
    text: "SUPERVISOR_HUB",
  },
  {
    value: 5,
    text: "DATA_ENTRY_HUB",
  },
  {
    value: 6,
    text: "OPERATION_HUB",
  },
  {
    value: 7,
    text: "DRIVER",
  },
];
