<template>
  <div class="DialogEditVendorComponent">
    <v-dialog v-model="dialogEditVendor" persistent max-width="600px">
      <v-card>
        <v-card-title> Update Vendor </v-card-title>

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.name"
                    outlined
                    dense
                    label="Nama Vendor"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.pic"
                    outlined
                    dense
                    label="PIC"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.phone"
                    outlined
                    dense
                    label="No. Handphone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.email"
                    outlined
                    dense
                    label="Email"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kota"
                    :rules="requiredRule"
                    :items="cityList"
                    :value="vendor.city"
                    @input="setCity"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kelurahan"
                    :rules="requiredRule"
                    :items="villageList"
                    :value="vendor.village"
                    @input="setVillage"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    v-model="vendor.address"
                    outlined
                    dense
                    label="Alamat"
                    rows="2"
                    no-resize
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>
          <v-btn color="warning" depressed @click="updateVendor">
            edit vendor
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogEditVendorComponent",
  props: ["vendor"],
  data() {
    return {
      dialogEditVendor: false,
      valid: false,
      snackbar: false,
      snackbarText: "",
      requiredRule: [(value) => !!value || "Required"],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogEditVendor = !this.dialogEditVendor;
    },
    updateVendor() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        axios({
          method: "put",
          url: `${baseUrl}/Transporter/${this.vendor.idTransporter}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            Nama: this.vendor.name,
            Email: this.vendor.email,
            Telp: this.vendor.phone,
            PIC: this.vendor.pic,
            Alamat: this.vendor.address,
            KelurahanIDKelurahan: this.vendor.idVillage,
            KelurahanKodePos: this.vendor.postCode,
            CityIDCity: this.vendor.idCity,
            KecamatanIDKecamatan: this.vendor.idDistrict,
          },
        })
          .then((res) => {
            this.toggleDialog();
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.vendor.idCity = city.idCity;
        this.vendor.city = e;
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtname = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtname
      );

      if (village) {
        this.vendor.idVillage = village.idKelurahan;
        this.vendor.idDistrict = village.idKecamatan;
        this.vendor.district = e;
        this.vendor.postCode = village.kodePos;
      }
    },
  },
  computed: {
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    villageList() {
      let villageArray = [];

      if (this.vendor.idCity) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.vendor.idCity;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
