<template>
  <div>
    <v-dialog v-model="dialogStatus" persistent max-width="400px">
      <v-card>
        <v-card-title> Status Area </v-card-title>

        <v-card-text>
          <v-form>
            <h3>{{ area.name }}</h3>
            <v-switch v-model="area.active" inset></v-switch>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog">cancel</v-btn>

          <v-btn color="warning" depressed @click="toggleDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogStatusAreaComponent",
  props: ["area"],
  data() {
    return {
      dialogStatus: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogStatus = !this.dialogStatus;
    },
  },
};
</script>

<style lang="scss" scoped></style>
