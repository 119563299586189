<template>
  <div class="Unbagging">
    <!-- <div class="Unbagging-Form">
      <v-container>
        <v-row>
          <v-col cols="6" class="pa-0">
            <v-text-field
              label="Manifest Number"
              outlined
              dense
              v-model="idManifest"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="pa-0 ml-2">
            <v-btn
              block
              depressed
              color="primary"
              @click="getOrders"
              :loading="isLoading"
              >sumbit</v-btn
            >
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col class="pa-0 mb-2">
            <h2>Koli: {{ totalColly }}</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0 mb-2">
            <h2>Berat : {{ totalWeight }} kg</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-btn depressed color="info">show data</v-btn>

            <v-btn depressed color="warning" @click="unbag"> unbag</v-btn>

            <v-btn
              class="ml-1"
              depressed
              color="warning"
              outlined
              @click="clear"
            >
              clear</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div> -->

    <hr />

    <div class="Bagging-Result">
      <h2 class="mb-2">List Order</h2>
      <v-data-table
        :headers="headers"
        :items="items"
        :sort-by="sortBy"
        :sort-desc="[true]"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            depressed
            class="mr-2"
            small
            color="success"
            @click="view(item)"
          >
            detail
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import moment from "moment";
export default {
  name: "Bagging",
  data() {
    return {
      idManifest: "",
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      sortBy: ["date", "destination", "totalColly", "totalWeight", "status"],
      headers: [
        { text: "ID Order", value: "idOrder" },
        { text: "WB Number", value: "wbNumber" },
        { text: "Date", value: "date" },
        // { text: "Asal", value: "origin" },
        { text: "Total Koli", value: "totalColly" },
        { text: "Total Berat", value: "totalWeight" },
        { text: "Status", value: "status" },
        // { text: "Action", value: "action", sortable: false },
      ],
      items: [],
    };
  },
  computed: {
    totalColly() {
      if (this.items.length != 0) {
        return this.items.reduce((acc, obj) => {
          return acc + obj.totalColly;
        }, 0);
      }
      return 0;
    },
    totalWeight() {
      if (this.items.length != 0) {
        return this.items.reduce((acc, obj) => {
          return acc + obj.totalWeight;
        }, 0);
      }
      return 0;
    },
  },
  methods: {
    view(item) {
      console.log(item);
    },
    clear() {
      this.items = [];
      this.idManifest = "";
    },
    getOrders() {
      this.isLoading = true;
      axios({
        url: `${baseUrl}/HubManifest/${this.idManifest}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;

          this.items = res.data.data.orders.map((el) => {
            return {
              idOrder: el.OrderIDOrder,
              wbNumber: el.WBNumber,
              date: moment(el.Date).format("YYYY-MM-DD, HH:mm"),
              // origin: "Bandung",
              totalColly: el.TotalKoli,
              totalWeight: el.TotalBerat,
              status: el.PackageStatus.Status,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    unbag() {
      axios({
        url: `${baseUrl}/HubManifest/${this.idManifest}`,
        method: "put",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: 7,
        },
      })
        .then((res) => {
          const { data } = res;
          this.snackbar = true;
          this.snackbarText = data.message.info[0];
        })
        .catch((err) => {
          const { data } = err.response;
          this.snackbarText = data.message.error[0];
          this.snackbar = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Unbagging {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  hr {
    margin: 16px auto;
  }

  &-Form {
    display: flex;
  }
}
</style>
