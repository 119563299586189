<template>
  <div class="OtpComponent">
    <v-form>
      <v-text-field
        outlined
        dense
        label="OTP"
        type="number"
        v-model="otp"
      ></v-text-field>

      <v-btn block outlined color="warning" @click="verify">verify </v-btn>
    </v-form>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../constant/url";
export default {
  name: "OtpComponent",
  props: ["phone", "password"],
  data() {
    return {
      otp: "",
      snackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    verify() {
      window.confirmationResult
        .confirm(String(this.otp))
        .then(() => {
          console.log("verified");
          this.verifyPhone();
        })
        .catch((err) => {
          this.snackbarText = err.message;
          this.snackbar = true;
        });
    },
    verifyPhone() {
      axios({
        method: "post",
        url: `${baseUrl}/Verify`,
        data: {
          Telp: this.phone,
          Password: this.password,
        },
      })
        .then((res) => {
          const { data } = res;
          this.snackbar = true;
          this.snackbarText = data.message.info[0];
          this.$emit("changeType", "login");
        })
        .catch((err) => {
          const { data } = err.response;
          this.snackbarText = data.message.error[0];
          this.snackbar = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
