<template>
  <div class="Vendormanagement">
    <div class="Vendormanagement-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              outlined
              dense
              v-model="vendor"
              label="Search Vendor"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <dialog-add-vendor-component @refresh="fetchTransporter" />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="vendor"
      :sort-by="sortBy"
      :loading="isLoading"
    >
      <template v-slot:top>
        <dialog-view-vendor-component
          :vendor="itemDetail"
          ref="viewVendorDialog"
        />

        <dialog-edit-vendor-component
          @refresh="fetchTransporter"
          :vendor="itemDetail"
          ref="editVendorDialog"
        />

        <dialog-delete-vendor-component
          @refresh="fetchTransporter"
          :vendor="itemDetail"
          ref="delteVendorDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="green" @click="view(item)">
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>

        <v-btn depressed class="mr-2" small color="blue" @click="edit(item)">
          <v-icon color="white"> mdi-pencil </v-icon>
        </v-btn>

        <v-btn depressed small color="error" @click="deleteItem(item)">
          <v-icon color="white"> mdi-exclamation </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAddVendorComponent from "../../../components/dashboard/settings/DialogAddVendorComponent.vue";
import DialogDeleteVendorComponent from "../../../components/dashboard/settings/DialogDeleteVendorComponent.vue";
import DialogEditVendorComponent from "../../../components/dashboard/settings/DialogEditVendorComponent.vue";
import DialogViewVendorComponent from "../../../components/dashboard/settings/DialogViewVendorComponent.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "Vendormanagement",
  components: {
    DialogAddVendorComponent,
    DialogViewVendorComponent,
    DialogEditVendorComponent,
    DialogDeleteVendorComponent,
  },
  data() {
    return {
      vendor: null,
      isLoading: false,
      itemDetail: {},
      options: [{ text: "transporter one", value: "transporterOne" }],
      sortBy: ["idTransporter", "name", "city", "email", "pic", "status"],
      headers: [
        { text: "ID", value: "idTransporter", filterable: false },
        { text: "Name", value: "name" },
        { text: "Kota", value: "city", filterable: false },
        { text: "Email", value: "email", filterable: false },
        { text: "Contact", value: "phone", filterable: false },
        { text: "PIC", value: "pic", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewVendorDialog.toggleDialog();
    },
    edit(item) {
      this.itemDetail = item;
      this.$refs.editVendorDialog.toggleDialog();
    },
    deleteItem(item) {
      this.itemDetail = item;
      this.$refs.delteVendorDialog.toggleDialog();
    },
    fetchTransporter() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/Transporter`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter(
            (el) => el.IDTransporter != 0
          );

          this.items = filteredItem.map((transporter) => {
            return {
              idTransporter: transporter.IDTransporter,
              name: transporter.Nama,
              city: transporter.City.NamaCity,
              idCity: transporter.City.IDCity,
              district: transporter.Kecamatan.NamaKecamatan,
              idDistrict: transporter.Kecamatan.IDKecamatan,
              village: transporter.Kelurahan.NamaKelurahan,
              idVillage: transporter.Kelurahan.IDKelurahan,
              status: transporter.Status,
              postCode: transporter.Kelurahan.KodePos,
              email: transporter.Email,
              phone: transporter.Telp,
              pic: transporter.PIC,
              address: transporter.Alamat,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
  },
  created() {
    this.fetchTransporter();
  },
};
</script>

<style lang="scss" scoped>
.Vendormanagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
