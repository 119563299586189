<template>
  <div class="DialogAddItemComponent">
    <v-dialog v-model="dialogAddItem" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block depressed color="success" v-bind="attrs" v-on="on">
          Tambah
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Add Item </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <!-- <v-text-field
              label="Jumlah Koli"
              type="number"
              :rules="requiredRule"
              outlined
              dense
              v-model="colly.numberColly"
            ></v-text-field> -->

            <v-text-field
              label="Berat, Kg"
              type="number"
              :rules="requiredRule"
              outlined
              dense
              v-model="colly.weight"
            ></v-text-field>

            <v-text-field
              label="Panjang, Cm"
              type="number"
              :rules="requiredRule"
              outlined
              dense
              v-model="colly.length"
            ></v-text-field>

            <v-text-field
              label="Lebar, Cm"
              type="number"
              :rules="requiredRule"
              outlined
              dense
              v-model="colly.width"
            ></v-text-field>

            <v-text-field
              label="Tinggi, Cm"
              type="number"
              :rules="requiredRule"
              outlined
              dense
              v-model="colly.height"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="dialogAddItem = false">
            cancel
          </v-btn>
          <v-btn color="warning" depressed @click="addColly"> add </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { calculateVolumetricWeight } from "../../../utils/calculation";

export default {
  name: "DialogAddItemComponent",
  data() {
    return {
      dialogAddItem: false,
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      snackbar: false,
      snackbarText: "",
      colly: {
        numberColly: 1,
        weight: "",
        length: "",
        width: "",
        height: "",
        volumeWeight: 0,
        isPacking: false,
        packingPrice: 0,
        mostWeight: 0,
        totalPrice: 0,
      },
    };
  },
  methods: {
    addColly() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        const collies = [...this.$store.state.order.itemData.collies];
        const volumeWeight = calculateVolumetricWeight(
          this.colly.length,
          this.colly.width,
          this.colly.height
        );
        const weight = Number(this.colly.weight);
        const mostWeight = volumeWeight > weight ? volumeWeight : weight;

        collies.push({
          numberColly: Number(this.colly.numberColly),
          weight,
          length: Number(this.colly.length),
          width: Number(this.colly.width),
          height: Number(this.colly.height),
          volumeWeight,
          isPacking: false,
          packingPrice: 0,
          mostWeight,
          totalPrice: 0,
        });

        const totalWeight = collies.reduce((acc, obj) => {
          return acc + obj.mostWeight;
        }, 0);

        this.$store.commit("order/SET_ITEM", { totalWeight });
        this.$store.commit("order/SET_ITEM", { collies });
        // this.valid = this.$refs.form.reset();
        // this.dialogAddItem = false;

        axios({
          method: "post",
          url: `${baseUrl}/CheckPrice`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            Length: 0,
            Height: 0,
            Width: 0,
            Weight: this.$store.state.order.itemData.totalWeight,
            IDCityOrigin: this.$store.state.order.shipperData.shipperCityId,
            KodePosDestination:
              this.$store.state.order.recipientData.recipientPostCode.toString(),
            KCX_ViaIDVia: 1,
            KCX_ServiceIDService: 9,
          },
        })
          .then((res) => {
            this.$store.commit("order/SET_ITEM", {
              kargoPrice: res.data.data.price,
            });
            this.valid = this.$refs.form.reset();
            this.dialogAddItem = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
