<template>
  <div>
    <div class="Tracking">
      <div class="Tracking-Form">
        <h2>Internal Tracking</h2>
        <v-form ref="form" v-model="valid">
          <v-container class="ma-0">
            <v-row>
              <v-col sm="4">
                <v-select
                  :rules="rules"
                  :items="typeOption"
                  v-model="type"
                  item-text="text"
                  item-value="value"
                  label="Type"
                  outlined
                  dense
                ></v-select>
              </v-col>

              <v-col sm="4">
                <v-text-field
                  :rules="rules"
                  v-model="number"
                  label="Insert Number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col sm="4">
                <v-btn
                  color="warning"
                  depressed
                  block
                  :loading="isTrackLoading"
                  @click="track"
                  >search</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <div class="Tracking-Result">
        <hr />
        <div v-if="isResult">
          <br />
          <h3>Origin: {{ result.Origin }}</h3>
          <h3>Destination: {{ result.Destination }}</h3>
          <h3>Status: {{ result.Status }}</h3>
        </div>
      </div>
    </div>

    <!-- # TODO: commented for now. uncomment later if want to use it -->
    <!-- <tracking-external-component /> -->

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
// import TrackingExternalComponent from "../../../components/dashboard/delivery/TrackingExternalComponent.vue";

export default {
  name: "Tracking",
  data() {
    return {
      valid: false,
      type: null,
      number: null,
      isResult: false,
      result: {
        Origin: "",
        Destination: "",
        Status: "",
      },
      isTrackLoading: false,
      snackbar: false,
      snackbarText: "",
      typeOption: [
        { text: "Wb Number", value: 1 },
        { text: "Order Id", value: 2 },
        { text: "Manifest Number", value: 3 },
      ],
      rules: [(value) => !!value || "Required"],
    };
  },
  methods: {
    track() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.isTrackLoading = true;
        this.isResult = false;
        this.result = {
          Origin: "",
          Destination: "",
          Status: "",
        };

        axios({
          url: `${baseUrl}/Track`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            type: this.type,
            id: this.number,
          },
        })
          .then((res) => {
            this.isTrackLoading = false;
            this.isResult = true;
            this.result = res.data.data;
          })
          .catch((err) => {
            const { data } = err.response;

            this.isTrackLoading = false;
            this.snackbarText = data.message.error[0];
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Tracking {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 20px;
}
</style>
