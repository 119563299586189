<template>
  <div class="SideBarComponent">
    <div class="SideBarComponent-Title">
      <!-- <h1>TROLLY</h1> -->
      <img :src="trollyLogo" alt="Trolly logo" id="TrollyLogo" />
    </div>

    <v-list color="#f47f4c" dense class="SideBarComponent-Navigation">
      <v-list-item router to="/dashboard/home" color="#65331D">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title>HOME</v-list-item-title>
      </v-list-item>
      <v-list-item router to="/dashboard/wallet" color="#65331D">
        <v-list-item-icon>
          <v-icon>mdi-wallet</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Wallet</v-list-item-title>
      </v-list-item>
      <v-list-item router to="/dashboard/financial-report" color="#65331D">
        <v-list-item-icon>
          <v-icon>mdi-book</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Financial Report</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item router to="/dashboard/cek-tarif" color="#65331D">
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text-search-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cek Tarif</v-list-item-title>
      </v-list-item> -->

      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        color="#65331D"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          router
          :to="child.route.path"
          color="#65331D"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import { dashboardRoute } from "../../constant/routeName";
import trollyLogo from "../../assets/images/trolly-logo-black.png";

export default {
  name: "SideBarComponent",
  data() {
    return {
      items: [
        {
          action: "mdi-clipboard-text-search-outline",
          items: [
            {
              title: "Domestik",
              route: dashboardRoute.fareCheck,
            },
            {
              title: "International",
              route: dashboardRoute.fareCheckInternational,
            },
            // {
            //   title: "Tracking",
            //   route: dashboardRoute.tracking,
            // },
          ],
          title: "CHECK TARIF",
        },
        {
          action: "mdi-package-variant-closed",
          items: [
            {
              title: "Order Management",
              route: dashboardRoute.orderManagement,
            },
            // {
            //   title: "Invoice Management",
            //   route: dashboardRoute.invoiceManagement,
            // },
            // TODO: uncomment if want to show Job Order Menu
            // {
            //   title: "Job Order",
            //   route: dashboardRoute.jobOrder,
            // },
            // {
            //   title: "Manage Pickup",
            //   route: dashboardRoute.managePickup,
            // },
            // {
            //   title: "Create Order",
            //   route: dashboardRoute.createOrder,
            // },
            {
              title: "Order Confirmation",
              route: dashboardRoute.orderConfirmation,
            },
          ],
          title: "INBOUND",
        },

        {
          action: "mdi-briefcase",
          items: [
            // TODO: uncomment if want to show bagging submenu
            // {
            //   title: "Bagging",
            //   route: dashboardRoute.bagging,
            // },
            // {
            //   title: "Packing List",
            //   route: dashboardRoute.packingList,
            // },
            // {
            //   title: "Manage Manifest",
            //   route: dashboardRoute.manageManifest,
            // },
            {
              title: "Manage Outbound",
              route: dashboardRoute.manageOutbound,
            },
            {
              title: "Incoming",
              route: dashboardRoute.incoming,
            },
            {
              // title: "Unbagging", #TODO: Rename unbagging to List Order
              title: "List Order",
              route: dashboardRoute.unbagging,
            },
          ],
          title: "OPERATION",
        },
        {
          action: "mdi-truck-delivery",
          items: [
            {
              title: "Irregularity",
              route: dashboardRoute.irregularity,
            },
            // {
            //   title: "Delivery Run Sheet",
            //   route: dashboardRoute.deliveryRunSheet,
            // },
            {
              title: "Manage Delivery",
              route: dashboardRoute.manageDelivery,
            },
            {
              title: "Outgoing",
              route: dashboardRoute.outgoing,
            },
          ],
          title: "DELIVERY",
        },
        {
          action: "mdi-folder",
          items: [
            // {
            //   title: "Shipment Status",
            //   route: dashboardRoute.shipmentStatus,
            // },
            {
              title: "Detail Transaction",
              route: dashboardRoute.detailTransaction,
            },
            {
              title: "Recap Transaction",
              route: dashboardRoute.recapTransaction,
            },
            // {
            //   title: "Wallet",
            //   route: dashboardRoute.wallet,
            // },
          ],
          title: "REPORTS",
        },
        {
          action: "mdi-cog",
          items: [
            // {
            //   title: "Area Management",
            //   route: dashboardRoute.areaManagement,
            // },
            {
              title: "Global Variable",
              route: dashboardRoute.globalVariable,
            },
            {
              title: "User Management",
              route: dashboardRoute.userManagement,
            },
            {
              title: "Hub Management",
              route: dashboardRoute.hubManagement,
            },
            // {
            //   title: "Vendor Management",
            //   route: dashboardRoute.vendorManagement,
            // },
            {
              title: "Profile",
              route: dashboardRoute.profile,
            },
            {
              title: "Logs",
              route: dashboardRoute.logs,
            },
          ],
          title: "SETTINGS",
        },
      ],
      trollyLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
.SideBarComponent {
  background-color: #f47f4c;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: scroll;

  &-Title {
    background-color: #f47f4c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}
#TrollyLogo {
  width: 10rem;
}
</style>
