<template>
  <div>
    <v-dialog v-model="dialogDeleteUser" persistent width="600px">
      <v-card>
        <v-card-title> Status User </v-card-title>

        <v-card-text>
          <h3>{{ user.name }}</h3>
          <v-switch v-model="isActive" inset></v-switch>
          <p>{{ user.status }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn
            color="warning"
            depressed
            @click="submit"
            :loading="loadingSubmit"
          >
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogDeleteUserComponent",
  props: ["user"],
  data() {
    return {
      dialogDeleteUser: false,
      snackbar: false,
      snackbarText: "",
      loadingSubmit: false,
    };
  },
  computed: {
    isActive: {
      get() {
        return this.user.status === "ACTIVE" ? true : false;
      },
      set(val) {
        val ? (this.user.status = "ACTIVE") : (this.user.status = "INACTIVE");
      },
    },
  },
  methods: {
    toggleDialog() {
      this.dialogDeleteUser = !this.dialogDeleteUser;
    },
    submit() {
      this.loadingSubmit = true;
      axios({
        method: "put",
        url: `${baseUrl}/User/${this.user.idUser}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          Status: this.user.status,
        },
      })
        .then((res) => {
          this.loadingSubmit = false;
          this.toggleDialog();
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.$emit("refresh");
        })
        .catch((err) => {
          this.loadingSubmit = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
