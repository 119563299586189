<template>
  <div class="Bagging">
    <div class="Bagging-Form">
      <v-container>
        <v-row>
          <v-col sm="6" class="pa-0">
            <v-text-field
              label="Wb Number"
              placeholder=""
              outlined
              dense
              clearable
              v-model="wbNumber"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="pa-0 ml-2">
            <v-btn block depressed color="secondary" @click="getOrder"
              >search</v-btn
            >
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col class="pa-0 mb-2">
            <h2>Koli: {{ totalColly }}</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0 mb-2">
            <h2>Berat : {{ totalWeight }} Kg</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-btn
              :loading="isLoading"
              depressed
              color="warning"
              @click="createPackingList"
              >tutup kantong</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>

    <hr />

    <div class="Bagging-Result">
      <h2 class="mb-2">Order List</h2>
      <v-data-table :headers="headers" :items="items" :sort-by="sortBy">
        <template v-slot:[`item.action`]="{ item }">
          <v-btn small color="error" depressed @click="deleteItem(item)">
            <v-icon color="white"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import moment from "moment";
export default {
  name: "Bagging",
  data() {
    return {
      isLoading: false,
      wbNumber: "",
      snackbar: false,
      snackbarText: "",
      sortBy: ["wbNumber", "date", "destination", "colly", "weight", "status"],
      headers: [
        { text: "Wb Number", value: "wbNumber" },
        { text: "Date", value: "date" },
        { text: "Tujuan", value: "destination" },
        { text: "Koli", value: "colly" },
        { text: "Berat", value: "weight" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      items: [],
    };
  },
  computed: {
    totalColly() {
      if (this.items.length == 0) {
        return 0;
      }
      return this.items.reduce(
        (accumulator, currentValue) => accumulator + currentValue.colly,
        0
      );
    },
    totalWeight() {
      if (this.items.length == 0) {
        return 0;
      }
      return this.items.reduce(
        (accumulator, currentValue) => accumulator + currentValue.weight,
        0
      );
    },
  },
  methods: {
    deleteItem(item) {
      const filteredItems = this.items.filter(
        (el) => el.wbNumber != item.wbNumber
      );
      this.items = filteredItems;
    },
    getOrder() {
      axios({
        url: `${baseUrl}/HubInboundOrder/Find?wbNumber=${this.wbNumber}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const { data } = res.data;
          this.items.push({
            wbNumber: data.WBNumber,
            date: moment(data.DateCreated)
              // .add(7, "hours")
              .format("YYYY-MM-DD, HH:mm"),
            destination: data.KotaTujuan,
            colly: data.TotalKoli,
            weight: data.TotalBerat,
            status: data.PackageStatus,
            idOrder: data.OrderIDOrder,
          });
          this.wbNumber = "";
        })
        .catch((err) => {
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
    createPackingList() {
      if (this.items.length === 0) {
        this.snackbar = true;
        this.snackbarText = "No order added";
        return;
      }

      this.isLoading = true;

      axios({
        url: `${baseUrl}/PackingList`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: this.items,
      })
        .then((res) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.items = [];
        })
        .catch((err) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Bagging {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  hr {
    margin: 16px auto;
  }

  &-Form {
    display: flex;
  }
}
</style>
