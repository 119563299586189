<template>
  <div class="ReceiptDocument">
    <loading-component v-if="isLoading"></loading-component>

    <div v-else>
      <div id="PrintReceiptAction">
        <h4>Print Receipt</h4>
        <v-btn @click="print" color="warning">Print</v-btn>
      </div>

      <div class="Receipt" id="Receipt">
        <table class="Receipt-Table">
          <tr>
            <!-- <td colspan="1">
              <div class="Brand">
                <h3 style="text-align: center">
                  <strong>TROLLY</strong>
                </h3>
              </div>
            </td> -->

            <td colspan="4">
              <div class="Title">
                <img :src="trollyLogo" alt="shipping logo" />

                <p>
                  <strong>BUKTI TITIP DAN PERINTAH PENGIRIMAN BARANG</strong>
                </p>

                <p>
                  <strong>{{ barcodeValue }}</strong>
                </p>

                <qrcode-vue :value="qrValue" :size="50" level="H"></qrcode-vue>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2" style="border-right: none !important">
              <div class="Recipient">
                <div class="Recipient-Detail">
                  <p>
                    <strong>Kepada :</strong>
                  </p>

                  <p>{{ order.recipientName }}</p>

                  <p>Alamat: {{ order.recipientAddress }}</p>

                  <p><strong>No Telp :</strong></p>

                  <p>{{ order.recipientPhone }}</p>
                </div>
              </div>
            </td>

            <td colspan="1" style="border-left: none !important">
              <table>
                <tr>
                  <td style="padding: 5px; text-align: center">
                    <strong>City Code</strong>
                  </td>
                </tr>

                <tr>
                  <td style="text-align: center">
                    <strong>{{ order.cityDestination }}</strong>
                    <br />
                    <strong>{{ order.recipientPostCode }}</strong>
                  </td>
                </tr>
              </table>
            </td>

            <td colspan="1">
              <div class="Cargo">
                <div class="Cargo-Colli">
                  <p>
                    <strong>Jumlah Titipan</strong>
                  </p>

                  <table>
                    <tr>
                      <td style="width: 80px; text-align: center">
                        <strong>Berat (Kg)</strong>
                      </td>

                      <td style="width: 80px; text-align: center">
                        <strong>Koli</strong>
                      </td>

                      <td style="width: 80px; text-align: center">
                        <strong>Service</strong>
                      </td>
                    </tr>

                    <tr>
                      <td style="text-align: center">
                        <strong>{{ order.totalWeight }}</strong>
                      </td>

                      <td style="text-align: center">
                        <strong>{{ order.totalColly }}</strong>
                      </td>

                      <td style="text-align: center">
                        <strong>DARAT</strong>
                      </td>
                    </tr>
                  </table>
                </div>

                <hr />

                <div style="text-align: start; padding-left: 5px">
                  <p>
                    <strong>Biaya Kirim: {{ order.price }}</strong>
                  </p>
                  <p>
                    <strong>Biaya Lain: {{ order.packingPrice }}</strong>
                  </p>
                  <p>
                    <strong>Total: {{ order.totalPrice }}</strong>
                  </p>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2" style="border-right: none !important">
              <div class="Shipper">
                <div class="Shipper-Detail">
                  <p>
                    <strong>Dari :</strong>
                  </p>

                  <p>{{ order.shipperName }}</p>

                  <p>Alamat: {{ order.shipperAddress }}</p>

                  <p><strong>No Telp :</strong></p>

                  <p>{{ order.shipperPhone }}</p>
                </div>

                <!-- <table>
                <tr>
                  <td>
                    <strong>City Code</strong>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>TJP</strong>
                    <br />
                    <strong>14460</strong>
                  </td>
                </tr>
              </table> -->
              </div>
            </td>

            <td colspan="1" style="border-left: none !important">
              <table>
                <tr>
                  <td style="padding: 5px; text-align: center">
                    <strong>City Code</strong>
                  </td>
                </tr>

                <tr>
                  <td style="text-align: center">
                    <strong>{{ order.cityOrigin }}</strong>
                    <br />
                    <strong style="text-align: center">{{
                      order.shipperPostCode
                    }}</strong>
                  </td>
                </tr>
              </table>
            </td>

            <td colspan="1" rowspan="2">
              <div class="Receipt--left">
                <p><strong>Terbilang : </strong></p>

                <p><strong>ISI MENURUT PENGAKUAN :</strong></p>

                <p>{{ order.description }}</p>

                <p><strong>Catatan</strong></p>

                <ol style="font-size: 11px; padding-left: 10px">
                  <li>
                    Alamat pengirim dan penerim harus ditulis dengan lengkap dan
                    jelas
                  </li>
                  <li>
                    Barang titipan diterima pda hari sabtu atau pada hari dimana
                    keesokan harinya adalah hari libur, akan dikirimkan pada
                    hari berikutnya
                  </li>
                  <li>
                    Alamat yang tidak jelas tidak dapat diterima termasuk alamat
                    dengan menggunakan PO BOX
                  </li>
                </ol>

                <div style="display: flex; align-items: center; gap: 20px">
                  <barcode
                    :value="barcodeValue"
                    text-margin="0"
                    background="none"
                    width="1"
                    height="40"
                    font-size="14"
                  >
                  </barcode>

                  <strong
                    >{{ order.cityOrigin }} /
                    {{ order.cityDestination }}</strong
                  >
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="3">
              <div class="Dissclaimer">
                <p
                  style="
                    font-size: 11px;
                    padding: 0 10px;
                    background-color: lightgrey;
                    margin-bottom: 5px;
                  "
                >
                  Dengan menyerahkan barang titipan ke Trolly maka kami
                  menyatakan bahwa semua pernyataan terkait barang tersebut
                  adalah benar dan kami tunduk kepada syarat dan ketentuan
                  pengiriman Trolly.
                </p>

                <div class="Dissclaimer-Sign">
                  <p><strong>Nama Pengirim</strong></p>
                  <p><strong>Tanda Tangan</strong></p>
                </div>
                <p style="text-align: start; pading-left: 5px; font-size: 11px">
                  Tanggal: {{ order.date }}
                </p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "../../components/dashboard/LoadingComponent.vue";

import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../constant/url";
import { getTokenCookie } from "../../utils/cookies";
import { formatToRupiah } from "../../utils/formatter";
import trollyLogo from "../../assets/images/trolly-logo.png";

export default {
  name: "Receipt",
  components: {
    barcode: VueBarcode,
    QrcodeVue,
    LoadingComponent,
  },
  data() {
    return {
      trollyLogo,
      barcodeValue: "",
      qrValue: "",
      order: {},
      isLoading: false,
    };
  },
  methods: {
    print() {
      window.print();
    },
    fetchDetail() {
      this.isLoading = true;
      const wbNumber = this.$route.query.wb;

      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders/${wbNumber}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          const { ShipperLoc } = data.orderResult.Order;
          const { Recipient } = data.orderResult.Order;

          this.barcodeValue = data.orderResult.WBNumber.replace(/ /g, "");
          this.qrValue = data.orderResult.WBNumber.replace(/ /g, "");

          this.order = {
            date: moment(data.orderResult.DateCreated).format("ll"),
            time: moment(data.orderResult.DateCreated).format("LT"),
            cityOrigin: res.data.data.CityOriginSNI,
            cityDestination: Recipient.City.CitySNI,
            hubOrigin: data.orderResult.Order.Hub.NamaHub,
            hubPhone: data.orderResult.Order.Hub.Telp,
            collies: data.orderResult.InboundCollies,
            packingPrice: formatToRupiah(data.orderResult.TotalBiayaPacking),
            price: formatToRupiah(data.orderResult.TotalBiaya),
            totalPrice: formatToRupiah(
              data.orderResult.TotalBiayaPacking + data.orderResult.TotalBiaya
            ),
            totalWeight: data.orderResult.TotalBerat,
            totalColly: data.orderResult.TotalKoli,
            wbNumber: data.orderResult.WBNumber,
            description: data.orderResult.Order.Keterangan,
            shipperName: ShipperLoc.Nama,
            shipperAddress: `${ShipperLoc.Alamat}, Kel. ${ShipperLoc.NamaKelurahan}, Kec. ${ShipperLoc.NamaKecamatan}, ${ShipperLoc.NamaCity}, ${ShipperLoc.KodePos}`,
            shipperPhone: ShipperLoc.Telp,
            shipperPostCode: ShipperLoc.KodePos,
            recipientCity: Recipient.City.NamaCity.toUpperCase(),
            recipientName: Recipient.Nama,
            recipientAddress: `${Recipient.Alamat}, Kel. ${Recipient.SubDistrict.NamaKelurahan}, ${Recipient.City.NamaCity}, ${Recipient.SubDistrict.KodePos}`,
            recipientPhone: Recipient.Telp,
            recipientPostCode: Recipient.SubDistrict.KodePos,
          };
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.fetchDetail();
  },
};
</script>

<style lang="scss" scoped>
.ReceiptDocument {
  background-color: grey;
  min-height: 100vh;

  #PrintReceiptAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      color: white;
    }
  }

  .Receipt {
    margin: 0 auto;

    td,
    th {
      border: 1px solid black;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .Title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // gap: 5px;
      padding: 5px;
    }

    .Recipient {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      &-Detail {
        text-align: start;
      }
    }

    .Shipper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      &-Detail {
        text-align: start;
      }
    }

    .Cargo {
      &-Colli {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px;
        // width: 400px;
      }

      hr {
        margin: 0;
      }
    }

    .Dissclaimer {
      &-Sign {
        display: flex;
        justify-content: space-around;
        height: 80px;
        background-color: lightgrey;
      }
    }

    &-Table {
      width: 900px;
      margin: 0 auto;
      background: white;

      img {
        width: 10rem;
      }
    }

    &--left {
      display: flex;
      flex-direction: column;
      text-align: start;
      padding: 5px;
    }

    &--center {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
    }
  }
}

@media print {
  .ReceiptDocument {
    background-color: white;
  }

  #PrintReceiptAction {
    visibility: hidden;
  }

  #Receipt {
    position: absolute;
    top: 0;
    margin: 10px auto;
    width: 400px;
  }
}
</style>
