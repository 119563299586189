<template>
  <div class="DashboardHome d-flex flex-column justify-center items-center">
    <div>
      <img :src="trollyTransparentLogo" alt="Trolly" width="500" />
    </div>
    <!-- <h2>Welcome to Trolly HO</h2> -->
    <!-- <h2>SOLARNET-CGK10000</h2> -->
  </div>
</template>

<script>
import trollyTransparentLogo from "../../assets/images/trolly-transparent-logo.png";
export default {
  name: "DashboardHome",
  data() {
    return {
      trollyTransparentLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
.DashboardHome {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}
</style>
