<template>
  <div>
    <v-dialog v-model="dialogCreateInvoice" persistent max-width="600px">
      <v-card>
        <v-card-title> Create Invoice </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Manifest Id</p>
                <p class="ma-0">
                  <strong>{{ manifest.manifestId }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Date</p>
                <p class="ma-0">
                  <strong>{{ manifest.date }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Origin</p>
                <p class="ma-0">
                  <strong>{{ manifest.origin }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Destintation</p>
                <p class="ma-0">
                  <strong>{{ manifest.destination }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Total Koli</p>
                <p class="ma-0">
                  <strong>{{ manifest.totalColly }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Total Berat</p>
                <p class="ma-0">
                  <strong>{{ manifest.totalWeight }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>
          </v-container>

          <br />

          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Harga"
                    v-model="price"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-select
                    outlined
                    dense
                    label="Transporter"
                    :items="transporterList"
                    v-model="idTransporter"
                    item-text="Nama"
                    :rules="requiredRule"
                    item-value="IDTransporter"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>
          <v-btn
            color="warning"
            depressed
            @click="submitInvoice"
            :loading="isSubmitLoading"
          >
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogCreateInvoiceComponent",
  props: ["manifest"],
  data() {
    return {
      valid: false,
      dialogCreateInvoice: false,
      transporterList: [],
      idTransporter: "",
      price: "",
      snackbar: false,
      snackbarText: "",
      isSubmitLoading: false,
      requiredRule: [(value) => !!value || "Required"],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogCreateInvoice = !this.dialogCreateInvoice;
    },
    submitInvoice() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.isSubmitLoading = true;
        axios({
          method: "put",
          url: `${baseUrl}/AssignTransporter/${this.manifest.manifestId}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            IDTransporter: this.idTransporter,
            Price: Number(this.price),
          },
        })
          .then((res) => {
            this.toggleDialog();
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
            this.isSubmitLoading = false;
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
            this.isSubmitLoading = false;
          });
      }
    },
  },
  created() {
    axios({
      method: "get",
      url: `${baseUrl}/Transporter`,
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    })
      .then((res) => {
        const transporterData = res.data.data.filter(
          (el) => el.IDTransporter != 0 && el.Status == "ACTIVE"
        );

        this.transporterList = transporterData;
      })
      .catch(() => {
        this.transporterList = [];
      });
  },
};
</script>

<style lang="scss" scoped></style>
