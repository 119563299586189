<template>
  <div>
    <loading-component v-if="isLoading"></loading-component>

    <div v-else class="LabelDocument">
      <div>
        <div id="PrintLabelAction">
          <h4>Print Label</h4>
          <v-btn @click="print" color="warning">Print</v-btn>
        </div>

        <div class="ShippingLabel" id="Label">
          <div v-for="colly in order.collies" :key="colly.IDInbKoli">
            <table>
              <tr>
                <td>
                  <div class="ShippingLabel--center">
                    <img :src="trollyLogo" alt="Trolly logo" id="TrollyLogo" />
                  </div>
                </td>

                <td colspan="2">
                  <div class="ShippingLabel--left">
                    <p>KOTA TUJUAN</p>
                    <h4>{{ order.cityDestination }}</h4>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <div class="Identifier">
                    <div>
                      <barcode
                        :value="barcodeValue"
                        text-margin="0"
                        background="none"
                        width="1"
                        height="60"
                        font-size="14"
                      >
                      </barcode>
                    </div>

                    <div class="mr-2">
                      <qrcode-vue
                        :value="qrValue"
                        :size="80"
                        level="H"
                      ></qrcode-vue>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-space-between ml-2 mr-2">
                    <p>{{ order.date }}, {{ order.time }}</p>
                    <p>reff: -</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="2" rowspan="2">
                  <div class="ShippingLabel--left">
                    <p><strong>Penerima:</strong></p>
                    <p>{{ order.recipientCity }} ({{ order.recipientName }})</p>
                    <p>
                      {{ order.recipientAddress }}
                    </p>
                    <p>{{ order.recipientPhone }}</p>
                  </div>
                </td>

                <td>
                  <div class="ShippingLabel--center">
                    <h4>RUTE</h4>
                    <p>{{ order.cityOrigin }} - {{ order.cityDestination }}</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div class="ShippingLabel--center">
                    <p>
                      <strong
                        >{{ colly.CodeId }} of
                        {{ order.collies.length }}</strong
                      >
                    </p>
                    <p>{{ colly.Terberat }} KG</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <div class="ShippingLabel--left">
                    <p><strong>Pengirim:</strong></p>
                    <p>{{ order.hubOrigin }}</p>
                    <p>{{ order.hubPhone }}</p>
                  </div>
                </td>

                <td>
                  <div class="ShippingLabel--center">
                    <p>{{ order.date }}</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <div class="ShippingLabel--left">
                    <p><strong>Keterangan:</strong></p>
                    <p>{{ order.description ? order.description : "-" }}</p>
                  </div>
                </td>

                <td>
                  <div class="ShippingLabel--center">
                    <h4>HUB CODE</h4>
                    <h4>{{ order.hubCode }}</h4>
                  </div>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <div class="ShippingLabel--left">
                    <p>
                      <strong>Instruksi:</strong>
                      {{ order.instruction ? order.instruction : "-" }}
                    </p>
                  </div>
                </td>

                <!-- <td>
                  <div class="ShippingLabel--center">&ensp;-&ensp;</div>
                </td>

                <td>
                  <div class="ShippingLabel--center">
                    <p>NCOD</p>
                  </div>
                </td> -->
              </tr>

              <tr>
                <td colspan="3">
                  <div class="d-flex justify-center pt-1 pb-1">
                    <img
                      :src="instructionLogo"
                      alt="Instruction Logo"
                      id="InstructionLogo"
                    />
                  </div>
                </td>
              </tr>
            </table>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "../../components/dashboard/LoadingComponent.vue";

import jsPdf from "jspdf";
import html2canvas from "html2canvas";
import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import { baseUrl } from "../../constant/url";
import { getTokenCookie } from "../../utils/cookies";
import moment from "moment";
import { formatToRupiah } from "../../utils/formatter";
import trollyLogo from "../../assets/images/trolly-logo-black.svg";
import instructionLogo from "../../assets/images/instruction-logo.jpeg";

export default {
  name: "LabelComponent",
  components: {
    barcode: VueBarcode,
    QrcodeVue,
    LoadingComponent,
  },
  data() {
    return {
      barcodeValue: "",
      trollyLogo,
      instructionLogo,
      qrValue: "",
      isLoading: false,
      order: {},
    };
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    fetchDetail() {
      const wbNumber = this.$route.query.wb;

      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders/${wbNumber}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          const { ShipperLoc } = data.orderResult.Order;
          const { Recipient } = data.orderResult.Order;

          this.barcodeValue = data.orderResult.WBNumber.replace(/ /g, "");
          this.qrValue = data.orderResult.WBNumber.replace(/ /g, "");

          this.order = {
            date: moment(data.orderResult.DateCreated).format("YYYY-MM-DD"),
            time: moment(data.orderResult.DateCreated).format("HH:mm"),
            cityOrigin: res.data.data.CityOriginSNI,
            cityDestination: Recipient.City.CitySNI,
            hubOrigin: data.orderResult.Order.Hub.NamaHub,
            hubCode: data.orderResult.Order.Hub.IDHub,
            hubPhone: data.orderResult.Order.Hub.Telp,
            collies: data.orderResult.InboundCollies,
            totalPrice: formatToRupiah(data.orderResult.TotalBiaya),
            totalWeight: data.orderResult.TotalBerat,
            totalColly: data.orderResult.TotalKoli,
            wbNumber: data.orderResult.WBNumber,
            description: data.orderResult.Order.Keterangan,
            instruction: data.orderResult.Order.Instruction,
            shipperName: ShipperLoc.Nama,
            shipperAddress: `${ShipperLoc.Alamat}, Kel. ${ShipperLoc.NamaKelurahan}, Kec. ${ShipperLoc.NamaKecamatan}, ${ShipperLoc.NamaCity}, ${ShipperLoc.KodePos}`,
            shipperPhone: ShipperLoc.Telp,
            shipperPostCode: ShipperLoc.KodePos,
            recipientCity: Recipient.City.NamaCity.toUpperCase(),
            recipientName: Recipient.Nama,
            recipientAddress: `${Recipient.Alamat}, Kel. ${Recipient.SubDistrict.NamaKelurahan}, ${Recipient.City.NamaCity}, ${Recipient.SubDistrict.KodePos}`,
            recipientPhone: Recipient.Telp,
            recipientPostCode: Recipient.SubDistrict.KodePos,
          };
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    generatePdf() {
      window.html2canvas = html2canvas;
      const doc = new jsPdf("p", "pt", "a6");
      doc.html(document.querySelector("#Label"), {
        callback: function (pdf) {
          pdf.save("label.pdf");
        },
      });
    },
    print() {
      window.print();
      // const area = document.getElementById("Label").outerHTML;
      // const container = window.open();
      // container.document.write(`<!DOCTYPE html>
      // <html>
      //   <head>
      //     <style>
      //     table{
      //       width: 320px;
      //       border-spacing: 0;
      //     }
      //     table, td{
      //       border: 1px solid black;
      //     }
      //     </style>
      //   </head>
      //   <body>
      //     ${area}
      //   </body>
      // </html>
      // `);
      // container.focus();
      // container.print();
      // container.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.LabelDocument {
  background-color: grey;
  min-height: 100vh;

  #PrintLabelAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      color: white;
    }
  }

  .ShippingLabel {
    margin: 0 auto;
    width: 10cm;
    background-color: white;

    #TrollyLogo {
      width: 10rem;
    }

    #InstructionLogo {
      width: 20rem;
      height: 4rem;
    }

    h4,
    h3,
    p {
      margin: 0;
      padding: 0;
    }

    table {
      border-spacing: 0;
      margin: 2px;
    }

    hr {
      border-top: 1px dashed black;
      margin: 5px auto;
    }

    td {
      border: 1px solid black;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    &--left {
      display: flex;
      flex-direction: column;
      text-align: start;
      padding: 5px;
    }

    &--center {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
    }

    .Identifier {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
    }
  }
}

@media print {
  .ShippingLabel {
    background-color: white;
  }
  #PrintLabelAction {
    visibility: hidden;
  }

  #Label {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    // margin: 0 auto;
  }
}

@page {
  margin: 0 auto;
}
</style>
