<template>
  <div class="DialogConfirmOrderComponent">
    <v-dialog v-model="dialogConfirmOrder" persistent max-width="600px">
      <v-card>
        <v-card-title> Confirm Payment </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Status</p>
                <p class="ma-0">
                  <strong>{{ order.status }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Order Id</p>
                <p class="ma-0">
                  <strong>{{ order.idOrder }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Wb Number</p>
                <p class="ma-0">
                  <strong>{{ order.wbNumber }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Date</p>
                <p class="ma-0">
                  <strong>{{ order.date }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Shipper</p>
                <p class="ma-0">
                  <strong>{{ order.shipper }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Shipper Phone</p>
                <p class="ma-0">
                  <strong>{{ order.phone }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Recipient</p>
                <p class="ma-0">
                  <strong>{{ order.recipient }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Koli</p>
                <p class="ma-0">
                  <strong>{{ order.colly }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Berat (Kg)</p>
                <p class="ma-0">
                  <strong>{{ order.weight }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Harga</p>
                <p class="ma-0">
                  <strong>{{ order.price }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <div v-if="order.idStatus === 8">
            <v-btn color="warning" outlined @click="toggleDialog">
              close
            </v-btn>

            <v-btn
              :loading="isLoading"
              class="ml-2"
              color="warning"
              depressed
              @click="confirmPayment"
            >
              confirm payment
            </v-btn>
          </div>

          <div v-else>
            <v-btn class="ml-2" color="warning" outlined @click="printLabel">
              print Label
            </v-btn>
            <v-btn class="ml-2" color="warning" outlined @click="printStt">
              print STT
            </v-btn>

            <v-btn class="ml-2" color="warning" depressed @click="toggleDialog">
              ok
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogConfirmOrderComponent",
  props: ["order"],
  data() {
    return {
      dialogConfirmOrder: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogConfirmOrder = !this.dialogConfirmOrder;
    },
    printLabel() {
      // this.$router.push(`/document/label?wb=${this.order.wbNumber}`);
      let routeData = this.$router.resolve(
        `/document/label?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    printStt() {
      let routeData = this.$router.resolve(
        `/document/receipt?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    confirmPayment() {
      this.isLoading = true;
      axios({
        method: "put",
        url: `${baseUrl}/HubInboundOrders/${this.order.wbNumber}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: 9,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.toggleDialog();
          this.$emit("refresh");
        })
        .catch((err) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
