<template>
  <div class="InvoiceManagement">
    <v-container class="ma-0">
      <v-row>
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="date"
            type="date"
            outlined
            label="Date"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="2" class="pa-0">
          <v-select
            class="mr-2"
            outlined
            dense
            label="Origin"
            item-text="text"
            item-value="value"
            :items="originList"
            v-model="origin"
          ></v-select>
        </v-col>

        <v-col sm="2" class="pa-0">
          <v-select
            outlined
            dense
            label="Destination"
            item-text="text"
            item-value="value"
            :items="destinationList"
            v-model="destination"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0">
          <v-select
            outlined
            dense
            label="Status"
            item-text="text"
            item-value="value"
            :items="statusList"
            v-model="status"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0 mr-2">
          <v-btn depressed color="warning" class="mr-2" @click="search"
            >Search</v-btn
          >

          <v-btn depressed color="error" @click="refresh">refresh</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
      item-key="manifestId"
      :expanded.sync="expanded"
      :single-expand="true"
      show-expand
    >
      <template v-slot:top>
        <dialog-create-invoice-component
          @refresh="fetchManifests"
          :manifest="itemDetail"
          ref="createInvoicetDialog"
        />
      </template>

      <!-- TODO: Hide this button for now -->
      <!-- <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          class="mr-2"
          small
          color="success"
          @click="createInvoice(item)"
          :disabled="item.status != 'MANIFEST_CREATED'"
        >
          create invoice
        </v-btn>

        <v-btn
          depressed
          small
          color="primary"
          @click="sendJne(item)"
          :disabled="item.status != 'MANIFEST_CREATED'"
        >
          jne
        </v-btn>
      </template> -->

      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <drop-down-manifest-component :order="item.order" />
        </td>
      </template>
    </v-data-table>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import DialogCreateInvoiceComponent from "../../../components/dashboard/inbound/DialogCreateInvoiceComponent.vue";
import { formatToRupiah } from "../../../utils/formatter";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import moment from "moment";
import DropDownManifestComponent from "../../../components/dashboard/inbound/DropDownManifestComponent.vue";

export default {
  components: { DialogCreateInvoiceComponent, DropDownManifestComponent },
  name: "InvoiceManagement",
  data() {
    return {
      date: "",
      origin: "",
      expanded: [],
      destination: "",
      snackbar: false,
      snackbarText: "",
      originList: [],
      destinationList: [],
      status: "",
      statusList: [],
      isLoading: false,
      itemDetail: {
        manifestId: "",
        date: "",
        moda: "",
        origin: "",
        destination: "",
        totalColly: "",
        totalWeight: "",
      },
      sortBy: [
        "date",
        "origin",
        "destination",
        "totalColly",
        "totalWeight",
        "totalPrice",
        "status",
      ],
      headers: [
        { text: "No Manifest", value: "manifestId" },
        { text: "Date", value: "date" },
        { text: "Hub Origin", value: "origin" },
        { text: "Tujuan", value: "destination" },
        { text: "Total Koli", value: "totalColly" },
        { text: "Total Berat", value: "totalWeight" },
        { text: "Total Harga", value: "totalPrice" },
        { text: "Keterangan", value: "description" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
        { text: "Info", value: "data-table-expand", filterable: false },
      ],
      items: [],
      filteredItems: [],
    };
  },
  methods: {
    search() {
      if (this.date) {
        this.filterDate();
      }

      if (this.origin) {
        this.filterOrigin();
      }

      if (this.destination) {
        this.filterDestination();
      }

      if (this.status) {
        this.filterStatus();
      }
    },
    refresh() {
      this.filteredItems = this.items;
      this.date = "";
      this.destination = "";
      this.origin = "";
      this.status = "";
    },
    generateList(data) {
      this.statusList = [...new Set(data.map((item) => item.status))];
      this.originList = [...new Set(data.map((item) => item.origin))];
      this.destinationList = [...new Set(data.map((item) => item.destination))];
    },
    filterOrigin() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.origin == this.origin;
      });
    },
    filterDestination() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.destination == this.destination;
      });
    },
    filterStatus() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.status == this.status;
      });
    },
    filterDate() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return (
          moment(el.date).format("YYYY-MM-DD") ==
          moment(this.date).format("YYYY-MM-DD")
        );
      });
    },
    createInvoice(item) {
      this.itemDetail = item;
      this.$refs.createInvoicetDialog.toggleDialog();
    },
    fetchManifests() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/Manifest`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "1,2,3,4,5,6,7",
        },
      })
        .then((res) => {
          const filteredManifest = res.data.data.filter(
            (el) => el.IDManifest != 0
          );

          const items = filteredManifest.map((manifest) => {
            return {
              manifestId: manifest.IDManifest,
              date: moment(manifest.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              origin: manifest.HubDetail.NamaHub,
              destination: manifest.KotaTujuan,
              totalColly: manifest.TotalKoli,
              totalWeight: manifest.TotalBerat,
              status: manifest.Status,
              description: manifest.Keterangan,
              idStatus: manifest.IDStatus,
              orderDetail: manifest.Order,
              hubDetail: manifest.HubDetail,
              order: manifest.Order.map((el) => {
                return {
                  orderId: el.Order.IDOrder,
                  wbNumber: el.WBNumber.replace(/\s/g, ""),
                  colly: el.Order.TotalKoli,
                  weight: el.Order.TotalBerat,
                  transaction: formatToRupiah(el.TotalBiaya),
                  idStatus: el.Order.PackageStatusIDPackageStatus,
                  detail: el.Order,
                  hub: manifest.HubDetail,
                };
              }),
              totalPrice: formatToRupiah(
                manifest.Order.reduce((acc, obj) => {
                  return acc + obj.Order.TotalBiaya;
                }, 0)
              ),
            };
          });

          this.items = items;
          this.filteredItems = items;
          this.generateList(items);
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.filteredItems = [];
          this.isLoading = false;
        });
    },
    sendJne(item) {
      axios({
        method: "put",
        url: `${baseUrl}/AssignJne/${item.manifestId}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then(() => {
          this.snackbar = true;
          this.snackbarText = "success";
          this.fetchManifests();
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = "error";
          this.fetchManifests();
        });
    },
  },
  created() {
    this.fetchManifests();
  },
};
</script>

<style lang="scss" scoped>
.InvoiceManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
