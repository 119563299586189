<template>
  <div>
    <loading-component v-if="isLoading"></loading-component>

    <div class="ManifestDocument" v-else>
      <div id="PrintManifestAction">
        <h4>Print Manifest</h4>
        <v-btn @click="print" color="warning">Print</v-btn>
      </div>

      <div class="Manifest" id="Manifest">
        <div class="Manifest-header">
          <img :src="trollyLogo" alt="shipping logo" />

          <table style="border-spacing: 0; text-align: center">
            <thead style="color: white; font-weight: 200">
              <td
                style="
                  border: 1px solid black;
                  padding: 2px 5px;
                  background: grey;
                "
              >
                Tujuan
              </td>
              <td
                style="
                  border: 1px solid black;
                  padding: 2px 5px;
                  background: grey;
                "
              >
                Kepada Yth. Cabang
              </td>
            </thead>

            <tbody>
              <tr>
                <td style="border: 1px solid black; padding: 2px 5px">BDG</td>
                <td style="border: 1px solid black; padding: 2px 5px">
                  Hub Bandung
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="Manifest-content">
          <div>
            <p>PT ETRANS INTRALOGISTIK INDONESIA</p>
            <p>Komplek Duta Harapan Indah</p>
            <p>Kota Administrasi Jakarta Utara 14460</p>
            <p>Telp: 08129968653, Email: dennis.wong@etransit.co.id</p>
          </div>

          <div style="text-align: center">
            <p><strong>DAFTAR KIRIMAN</strong></p>
            <p><strong>(MANIFEST)</strong></p>
            <p>
              <strong>{{ manifestId }}</strong>
            </p>
            <p>
              <strong>Tanggal: {{ manifestDate }}</strong>
            </p>
          </div>

          <div>
            <p>No Polisi :</p>
            <p>Driver :</p>
            <p>Jenis Armada :</p>
            <p>No Hp :</p>
          </div>
        </div>

        <div class="Manifest-order">
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>No. AWB</th>
                <th>Tujuan</th>
                <th>Pengirim</th>
                <th>Penerima</th>
                <th>Isi Barang</th>
                <th>Koli</th>
                <th>Kilo</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(order, index) in listOrder" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ order.WBNumber }}</td>
                <td>{{ order.Order.Recipient.Kota }}</td>
                <td>{{ order.Order.Recipient.Nama }}</td>
                <td>{{ order.Order.ShipperLoc.Nama }}</td>
                <td>{{ order.Keterangan }}</td>
                <td>{{ order.TotalKoli }}</td>
                <td>{{ order.TotalBerat }}</td>
              </tr>
            </tbody>

            <tr>
              <td colspan="5"></td>
              <td class="sum">Total</td>
              <td class="sum">{{ totalColly }}</td>
              <td class="sum">{{ totalWeight }}</td>
            </tr>
          </table>
        </div>
        <div class="Manifest-footer">
          <div>
            <table>
              <tr>
                <td style="width: 100px">Nama</td>
                <td style="width: 100px"></td>
              </tr>
              <tr>
                <td>Tanggal</td>
                <td style="width: 200px"></td>
              </tr>

              <tr>
                <td colspan="2" style="text-align: center">Paraf</td>
              </tr>

              <tr>
                <td colspan="2" style="height: 100px">
                  <div
                    class="d-flex flex-column justify-end align-center"
                    style="height: 100%"
                  >
                    ( Diserahkan Oleh )
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div>
            <table>
              <table>
                <tr>
                  <td style="width: 100px">Nama</td>
                  <td style="width: 100px"></td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td style="width: 200px"></td>
                </tr>

                <tr>
                  <td colspan="2" style="text-align: center">Paraf</td>
                </tr>

                <tr>
                  <td colspan="2" style="height: 100px">
                    <div
                      class="d-flex flex-column justify-end align-center"
                      style="height: 100%"
                    >
                      ( Diterima Oleh )
                    </div>
                  </td>
                </tr>
              </table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "../../components/dashboard/LoadingComponent.vue";
import trollyLogo from "../../assets/images/trolly-logo.png";
import { baseUrl } from "../../constant/url";
import axios from "axios";
import { getTokenCookie } from "../../utils/cookies";
import { formatToRupiah } from "../../utils/formatter";
import moment from "moment";

export default {
  components: { LoadingComponent },
  name: "Manifest",
  data() {
    return {
      isLoading: false,
      trollyLogo,
      totalWeight: "",
      totalColly: "",
      totalPrice: "",
      listOrder: [],
      manifestId: "",
      manifestDate: "",
    };
  },
  methods: {
    print() {
      window.print();
    },
    toRupiah(value) {
      return formatToRupiah(value);
    },
    fetchDetail() {
      this.isLoading = true;
      const manifestId = this.$route.query.manifestId;

      axios({
        url: `${baseUrl}/HubManifest/${manifestId}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const { data } = res.data;
          console.log(data);
          let totalKoli = 0;
          let totalWeight = 0;
          let totalPrice = 0;

          data.orders.forEach((el) => {
            totalKoli += el.TotalKoli;
            totalWeight += el.TotalBerat;
            totalPrice += el.TotalBiaya;
          });

          this.totalWeight = totalWeight;
          this.totalColly = totalKoli;
          this.totalPrice = totalPrice;

          this.listOrder = data.orders;
          this.manifestId = data.detail.IDManifest;
          this.manifestDate = moment(data.detail.Date).format("YYYY-MM-DD");
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.fetchDetail();
  },
};
</script>

<style lang="scss" scoped>
.ManifestDocument {
  background-color: grey;
  min-height: 100vh;

  #PrintManifestAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      color: white;
    }
  }

  .Manifest {
    max-width: 45rem;
    background-color: white;
    margin: auto;
    padding: 10px;
    border: 1px solid grey;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      img {
        width: 10rem;
      }
    }

    &-content {
      margin: 20px auto;
      display: flex;
      gap: 60px;

      p {
        margin: 0;
        font-size: 12px;
      }
    }

    &-order {
      margin: 20px auto;
      table {
        border-spacing: 0;
        width: 100%;

        tbody {
          border: 1px grey solid;
          box-shadow: 0 0 0 1px grey;

          td {
            text-align: center;
            font-size: 12px;
          }
        }

        .sum {
          background: grey;
          color: white;
          text-align: center;
          border: 1px black solid;
          padding: 2px;
          font-size: 12px;
        }

        th {
          background-color: grey;
          box-shadow: 0 0 0 1px black;
          color: white;
          font-weight: 400;
          border: 1px black solid;
          text-align: center;
          padding: 2px 5px;
          font-size: 12px;
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      table {
        border-spacing: 0;
        td {
          border: 1px solid black;
          padding: 2px;
        }
      }
    }
  }
}

@media print {
  .ManifestDocument {
    background-color: white;
  }

  #PrintManifestAction {
    visibility: hidden;
  }

  #Manifest {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 45rem;
    margin: 10px auto;
  }
}
</style>
