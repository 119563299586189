<template>
  <div class="ItemFormComponent">
    <div class="ItemFormComponent-Header">
      <h3>Package Information</h3>
      <hr />
    </div>

    <div class="ItemFormComponent-Container">
      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0">
            <h4>Via Moda</h4>
          </v-col>

          <v-col cols="8" class="pa-0">
            <v-select
              outlined
              disabled
              dense
              :value="moda"
              @input="setModa"
              :items="modaOption"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Total Koli</h4></v-col>

          <v-col cols="4" class="pa-0">
            <v-text-field
              :value="totalColly"
              disabled
              outlined
              dense
              :rules="positiveNumberRule"
            ></v-text-field>
          </v-col>

          <v-col cols="4" class="pa-0 pl-2">
            <!-- <v-btn block depressed color="success"> tambah </v-btn>
             -->
            <dialog-add-item-component />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Keterangan</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-textarea
              :rules="rules"
              outlined
              rows="2"
              no-resize
              :value="description"
              @input="setDescription"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Instruksi Khusus</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-textarea
              :rules="rules"
              outlined
              rows="2"
              no-resize
              :value="instruction"
              @input="setInstruction"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Diskon</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              type="number"
              outlined
              dense
              :value="discount"
              @input="setDiscount"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>PPN</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              type="number"
              outlined
              dense
              :value="tax"
              @input="setTax"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Nilai Barang</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              type="number"
              outlined
              dense
              :value="estimateValue"
              @input="setEstimateValue"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0 ma-0">
            <v-checkbox
              label="Asuransi"
              :value="insurance"
              @change="setInsurance"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="ItemFormComponent-Result" v-if="collyDetail.length > 0">
      <v-data-table :headers="headers" :items="collyDetail">
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            depressed
            class="mr-2"
            small
            color="red"
            @click="deleteItem(item)"
          >
            <v-icon color="white"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import DialogAddItemComponent from "./DialogAddItemComponent.vue";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "ItemFormComponent",
  components: { DialogAddItemComponent },
  data() {
    return {
      rules: [(value) => !!value || "Required"],
      positiveNumberRule: [(value) => value > 0 || "Add item"],
      modaOption: ["Darat", "Laut", "Udara"],
      headers: [
        // { text: "#", value: "number", sortable: false, filterable: false },
        { text: "Koli", value: "numberColly" },
        { text: "Panjang", value: "length" },
        { text: "Lebar", value: "width", filterable: false },
        { text: "Tinggi", value: "height", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Berat Volume", value: "volumeWeight", filterable: false },
        { text: "Biaya Packing", value: "packingPrice", filterable: false },
        { text: "Terberat", value: "mostWeight", filterable: false },
        // { text: "Total Biaya", value: "totalPrice", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
    };
  },
  computed: {
    moda() {
      return this.$store.state.order.itemData.moda;
    },
    description() {
      return this.$store.state.order.itemData.description;
    },
    instruction() {
      return this.$store.state.order.itemData.instruction;
    },
    discount() {
      return this.$store.state.order.itemData.discount;
    },
    tax() {
      return this.$store.state.order.itemData.tax;
    },
    estimateValue() {
      return this.$store.state.order.itemData.estimateValue;
    },
    insurance() {
      return this.$store.state.order.itemData.isInsurance;
    },
    totalColly() {
      const collies = this.$store.state.order.itemData.collies;

      return collies.length;
    },
    collyDetail() {
      return this.$store.state.order.itemData.collies;
    },
  },
  methods: {
    setModa(e) {
      this.$store.commit("order/SET_ITEM", { moda: e });
    },
    setDescription(e) {
      this.$store.commit("order/SET_ITEM", { description: e });
    },
    setInstruction(e) {
      this.$store.commit("order/SET_ITEM", { instruction: e });
    },
    setDiscount(e) {
      this.$store.commit("order/SET_ITEM", { discount: e });
    },
    setTax(e) {
      this.$store.commit("order/SET_ITEM", { tax: e });
    },
    setEstimateValue(e) {
      this.$store.commit("order/SET_ITEM", { estimateValue: e });
    },
    setInsurance(e) {
      this.$store.commit("order/SET_ITEM", { isInsurance: e });
    },
    deleteItem(e) {
      const collies = [...this.$store.state.order.itemData.collies];

      const index = collies.findIndex((el) => {
        return el == e;
      });

      collies.splice(index, 1);
      const totalWeight = collies.reduce((acc, obj) => {
        return acc + obj.mostWeight;
      }, 0);

      this.$store.commit("order/SET_ITEM", { totalWeight });
      this.$store.commit("order/SET_ITEM", { collies });

      if (totalWeight != 0) {
        axios({
          method: "post",
          url: `${baseUrl}/CheckPrice`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            Length: 0,
            Height: 0,
            Width: 0,
            Weight: this.$store.state.order.itemData.totalWeight,
            IDCityOrigin: this.$store.state.order.shipperData.shipperCityId,
            KodePosDestination:
              this.$store.state.order.recipientData.recipientPostCode.toString(),
            KCX_ViaIDVia: 1,
            KCX_ServiceIDService: 9,
          },
        })
          .then((res) => {
            this.$store.commit("order/SET_ITEM", {
              kargoPrice: res.data.data.price,
            });
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }

      if (totalWeight == 0) {
        this.$store.commit("order/SET_ITEM", {
          kargoPrice: 0,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ItemFormComponent {
  padding: 0 20px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
