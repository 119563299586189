<template>
  <div class="CreateOrder">
    <v-form ref="form" v-model="valid" lazy-validation>
      <ShipperFormComponent />
      <br />
      <RecipientFormComponent />
      <br />
      <ItemFormComponent />
      <hr />

      <div class="CreateOrder-Summary">
        <v-container>
          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Koli</v-col>
            <v-col cols="2" class="pa-0">{{ collies }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Total Berat</v-col>
            <v-col cols="2" class="pa-0">{{ mostWeight }} Kg</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Total Biaya Packing</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(packingPrice) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Biaya Kirim</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(sendPrice) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Asuransi</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(insurance) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Diskon</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(discount) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0"><strong>Total Biaya</strong></v-col>
            <v-col cols="2" class="pa-0"
              ><strong>{{ toRupiah(totalPrice) }}</strong></v-col
            >
          </v-row>
        </v-container>
      </div>

      <div class="CreateOrder-Action">
        <v-btn outlined color="warning" width="200" @click="clear">clear</v-btn>

        <v-btn
          :loading="isLoading"
          depressed
          color="warning"
          width="200"
          class="ml-2"
          @click="book"
          >book</v-btn
        >
      </div>
    </v-form>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ShipperFormComponent from "@/components/dashboard/inbound/ShipperFormComponent.vue";
import RecipientFormComponent from "@/components/dashboard/inbound/RecipientFormComponent.vue";
import ItemFormComponent from "@/components/dashboard/inbound/ItemFormComponent.vue";

import { formatToRupiah } from "../../../utils/formatter";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";

export default {
  name: "CreateOrder",
  components: {
    ShipperFormComponent,
    RecipientFormComponent,
    ItemFormComponent,
  },
  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
    };
  },
  methods: {
    book() {
      this.valid = this.$refs.form.validate();
      const hubId = getProfileCookie().KCX_HubIDHub;

      if (this.valid) {
        this.isLoading = true;
        let endPoint = "";

        const isWalkin = this.$store.state.order.isWalkin;
        const idShipper = this.$store.state.order.idShipper;
        const idRecipient = this.$store.state.order.idRecipient;
        const idCollies = this.$store.state.order.idCollies;
        const idOrder = this.$store.state.order.idOrder;
        const shipper = this.$store.state.order.shipperData;
        const recipient = this.$store.state.order.recipientData;
        const item = this.$store.state.order.itemData;

        if (isWalkin) {
          endPoint = "HubOrders";
        } else {
          endPoint = "BookOrder";
        }

        axios({
          url: `${baseUrl}/${endPoint}`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            hub: hubId,
          },
          data: {
            idOrder,
            idShipper,
            idRecipient,
            idCollies,
            shipper: {
              Nama: shipper.shipperName,
              Telp: shipper.shipperPhone,
              Email: shipper.shipperEmail,
              Alamat: shipper.shipperAddress,
              IDCity: shipper.shipperCityId,
              NamaCity: shipper.shipperCity,
              NamaKecamatan: shipper.shipperKecamatan,
              NamaKelurahan: shipper.shipperKelurahan,
              KodePos: shipper.shipperPostCode,
              IDKecamatan: shipper.shipperKecamatanId,
              IDKelurahan: shipper.shipperKelurahanId,
            },
            recipient: {
              Nama: recipient.recipientName,
              Telp: recipient.recipientPhone,
              Email: recipient.recipientEmail,
              Kota: recipient.recipientCity,
              Alamat: recipient.recipientAddress,
              CityIDCity: recipient.recipientCityId,
              KelurahanIDKelurahan: recipient.recipientKelurahanId,
              KelurahanKodePos: recipient.recipientPostCode,
            },
            item: {
              Moda: item.moda,
              Keterangan: item.description,
              Instruction: item.instruction,
              Diskon: item.discount,
              PPN: item.tax,
              EstimasiNilai: item.estimateValue,
              Asuransi: item.insurance,
              TotalKoli: this.collies,
              TotalBerat: this.mostWeight,
              TotalBiayaPacking: this.packingPrice,
              BiayaKirim: this.sendPrice,
              TotalBiaya: this.totalPrice,
              collies: item.collies.map((el) => {
                return {
                  JumlahKoli: el.numberColly,
                  Berat: el.weight,
                  Panjang: el.length,
                  Lebar: el.width,
                  Tinggi: el.height,
                  BeratVolume: el.volumeWeight,
                  Terberat: el.mostWeight,
                  IsPacking: el.isPacking,
                  BiayaPacking: el.packingPrice,
                  TotalBiaya: 0,
                };
              }),
            },
          },
        })
          .then((res) => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.clear();
          })
          .catch((err) => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    clear() {
      this.$store.commit("order/CLEAR_DATA");
      this.valid = this.$refs.form.resetValidation();
    },
    toRupiah(value) {
      return formatToRupiah(value);
    },
  },
  computed: {
    collies() {
      const collies = this.$store.state.order.itemData.collies;

      if (collies.length != 0) {
        return collies.reduce((acc, obj) => {
          return acc + obj.numberColly;
        }, 0);
      }
      return 0;
    },
    mostWeight() {
      return this.$store.state.order.itemData.totalWeight;
      // if (collies.length != 0) {
      //   return collies.reduce((acc, obj) => {
      //     return acc + obj.mostWeight;
      //   }, 0);
      // }
      // return 0;
    },
    packingPrice() {
      const collies = this.$store.state.order.itemData.collies;
      if (collies.length != 0) {
        return collies.reduce((acc, obj) => {
          return acc + obj.packingPrice;
        }, 0);
      }
      return 0;
    },
    sendPrice() {
      return this.$store.state.order.itemData.kargoPrice;
    },
    insurance() {
      return this.$store.state.order.itemData.insurance;
    },
    discount() {
      return this.$store.state.order.itemData.discount;
    },
    tax() {
      return this.$store.state.order.itemData.tax;
    },
    totalPrice() {
      const total =
        this.packingPrice + this.sendPrice + this.tax - this.discount;
      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateOrder {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  hr {
    margin: 16px;
  }

  &-Action {
    text-align: right;
    padding: 16px;
  }

  &-Summary {
    text-align: right;
  }
}
</style>
