<template>
  <div class="RecipientFormComponent">
    <div class="RecipientFormComponent-Header">
      <h3>Recipient Information</h3>
      <hr />
    </div>

    <div class="RecipientFormComponent-Container">
      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0">
            <h4>Nama Penerima</h4>
          </v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rules"
              :value="recipientName"
              @input="setRecipientName"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>No. Handphone</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rules"
              :value="recipientPhone"
              @input="setRecipientPhone"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Email</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :value="recipientEmail"
              @input="setRecipientEmail"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kota</h4></v-col>

          <v-col cols="8" class="pa-0">
            <!-- <v-text-field :rules="rules" outlined dense></v-text-field>
             -->
            <v-combobox
              outlined
              dense
              :rules="rules"
              :items="cityList"
              :value="city"
              @input="setCity"
            ></v-combobox>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="pa-0">
            <v-checkbox label="Tambah ke address book"></v-checkbox>
          </v-col>
        </v-row> -->
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kelurahan</h4></v-col>

          <v-col cols="8" class="pa-0">
            <!-- <v-text-field :rules="rules" outlined dense></v-text-field>
             -->
            <v-combobox
              outlined
              dense
              :rules="rules"
              :items="villageList"
              :value="village"
              @input="setVillage"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kode Pos</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rules"
              :value="postCode"
              @input="setPostCode"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Alamat</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-textarea
              :rules="rules"
              :value="address"
              @input="setAddress"
              outlined
              rows="3"
              no-resize
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";

export default {
  name: "RecipientFormComponent",
  data() {
    return {
      rules: [(value) => !!value || "Required"],
    };
  },
  computed: {
    recipientName() {
      return this.$store.state.order.recipientData.recipientName;
    },
    recipientPhone() {
      return this.$store.state.order.recipientData.recipientPhone;
    },
    recipientEmail() {
      return this.$store.state.order.recipientData.recipientEmail;
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    city() {
      return this.$store.state.order.recipientData.recipientCity;
    },
    cityId() {
      return this.$store.state.order.recipientData.recipientCityId;
    },
    villageList() {
      let villageArray = [];
      if (this.cityId) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.cityId;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
    village() {
      return this.$store.state.order.recipientData.recipientKelurahan;
    },
    villageId() {
      return this.$store.state.order.recipientData.recipientKelurahanId;
    },
    postCode() {
      return this.$store.state.order.recipientData.recipientPostCode;
    },
    address() {
      return this.$store.state.order.recipientData.recipientAddress;
    },
  },
  created() {},
  watch: {},
  methods: {
    setRecipientName(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientName: e });
    },
    setRecipientPhone(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientPhone: e });
    },
    setRecipientEmail(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientEmail: e });
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.$store.commit("order/SET_RECIPIENT", {
          recipientCityId: city.idCity,
          recipientCity: e,
        });
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtname = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtname
      );

      if (village) {
        this.$store.commit("order/SET_RECIPIENT", {
          recipientKelurahanId: village.idKelurahan,
          recipientKecamatanId: village.idKecamatan,
          recipientKelurahan: e,
          recipientPostCode: village.kodePos,
        });
      }
    },
    setPostCode(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientPostCode: e });
    },
    setAddress(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientAddress: e });
    },
  },
};
</script>

<style lang="scss" scoped>
.RecipientFormComponent {
  padding: 0 20px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
