<template>
  <div class="DialogSendToSentralComponent">
    <v-dialog v-model="dialogSendJne" persistent max-width="600px">
      <v-card>
        <v-card-title> Assign Sentral </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dataStore.datePickup"
                      label="Date Pickup"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataStore.datePickup"
                    no-title
                    scrollable
                    @input="menuDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="dataStore.timePickup"
                  label="Time Pickup"
                  prepend-icon="mdi-clock-time-four-outline"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn
            color="warning"
            :loading="isLoading"
            depressed
            @click="assignSentral"
          >
            Assign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogSendToSentralComponent",
  props: ["detailItem", "fetchManifests"],
  data() {
    return {
      dialogSendJne: false,
      valid: false,
      snackbar: false,
      snackbarText: "",
      requiredRule: [(value) => !!value || "Required"],
      dataStore: {
        items: "",
        datePickup: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        timePickup: null,
      },
      date: "",
      touched: false,
      isLoading: false,
      menuDate: false,
      timeDate: false,
    };
  },

  methods: {
    toggleDialog() {
      this.dialogSendJne = !this.dialogSendJne;
    },
    setTime() {
      this.dataStore.datePickup = this.detailItem.tPickup[0];
      this.dataStore.timePickup = this.detailItem.tPickup[1];
    },
    assignSentral() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.isLoading = true;
        this.dataStore.items = this.detailItem;
        // console.log(this.dataStore);
        axios({
          method: "put",
          url: `${baseUrl}/AssignSentral/${this.detailItem.manifestId}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: this.dataStore,
        })
          .then(() => {
            this.snackbar = true;
            this.snackbarText = "success";
            this.toggleDialog();
            this.fetchManifests();
            this.isLoading = false;
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = "error";
            this.toggleDialog();
            this.fetchManifests();
            this.isLoading = false;
          });
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
