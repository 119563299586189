<template>
  <div class="Wallet">
    <v-row>
      <v-col cols="12" sm="12" class="pa-5">
        <h2>Saldo Trolly</h2>
        <p>Saldo tersedia saat ini</p>
      </v-col>
      <v-col cols="4" sm="4">
        <v-card class="pa-5" outlined tile>
          <v-row>
            <v-col cols="3" class="text-center">
              <v-icon large color="orange  darken-2">mdi-wallet</v-icon>
            </v-col>
            <v-col cols="9">
              <div class="pb-2">
                Saldo Aktif : <br />
                <strong>Rp. 1.000.000</strong>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-3 pa-3" outlined tile>
          <v-row>
            <v-col cols="12">
              <div><h3>Isi Saldo</h3></div>
              <div>
                <small>Masukkan nominal yang anda inginkan</small>
              </div>
              <div class="mt-3 mb-3">
                <v-btn-toggle
                  v-model="amount"
                  tile
                  color="deep-orange accent-3"
                  group
                >
                  <v-btn small value="100000"> Rp. 100.000 </v-btn>
                  <v-btn small value="300000"> Rp. 300.000 </v-btn>
                  <v-btn small value="500000"> Rp. 500.000 </v-btn>
                </v-btn-toggle>
              </div>
              <div class="mt-3 mb-3">
                <v-text-field
                  label="Amount"
                  prepend-icon="mdi-currency-usd"
                  :value="amount"
                ></v-text-field>
              </div>
              <v-btn
                @click="popupTopup = true"
                class="mb-2"
                block
                color="warning"
                dark
                >Isi Saldo
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="8" sm="8">
        <v-card class="pa-3" outlined tile>
          <div>
            <h3>Riwayat Saldo</h3>
            <small>Lihat Riwayat Saldo Trolly Anda</small>
          </div>
          <div class="mt-3">
            <v-tabs>
              <v-tab> SEMUA SALDO </v-tab>
              <v-tab> SALDO MASUK </v-tab>
              <v-tab> SALDO KELUAR </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-title>
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-card-title>
                          <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :search="search"
                          ></v-data-table>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-title>
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-card-title>
                          <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :search="search"
                          ></v-data-table>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-title>
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-card-title>
                          <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :search="search"
                          ></v-data-table>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="popupTopup"
      persistent
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar color="warning" dark>Isi Saldo</v-toolbar>
        <v-card-text>
          <div class="pa-5">
            <h3>No. VA</h3>
            <p>9980809808766787958</p>
          </div>
          <div>
            <v-btn color="primary" dark>Sudah Tranfer </v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="popupTopup = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Wallet",
  data: () => ({
    amount: 500000,
    popupTopup: false,
    search: "",
    headers: [
      {
        text: "Date",
        align: "start",
        filterable: false,
        value: "date",
      },
      { text: "Trx. ID", value: "trxid" },
      { text: "Trx. Type", value: "type" },
      { text: "Amuount", value: "amount" },
      { text: "Balance", value: "balanc" },
    ],
    desserts: [
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 24,
        balanc: 4.0,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 37,
        balanc: 4.3,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 23,
        balanc: 6.0,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 67,
        balanc: 4.3,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 49,
        balanc: 3.9,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 94,
        balanc: 0.0,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 98,
        balanc: 0,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 87,
        balanc: 6.5,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 51,
        balanc: 4.9,
      },
      {
        date: "01-07-2023",
        trxid: "trl-o-oskk933",
        type: "TOPUP",
        amount: 65,
        balanc: 7,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>
