<template>
  <div class="GlobalvarManagement">
    <div class="GlobalvarManagement-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              outlined
              dense
              v-model="globalvar"
              label="Search Globalvar"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="globalvar"
      :sort-by="sortBy"
      :loading="isLoading"
    >
      <template v-slot:top>
        <dialog-edit-globalvar-component
          @refresh="fetchHub"
          :globalvar="itemDetail"
          ref="editGlobalvarDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="blue" @click="edit(item)">
          <v-icon color="white"> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogEditGlobalvarComponent from "../../../components/dashboard/settings/DialogEditGlobalvarComponent.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "GlobalvarManagement",
  components: {
    DialogEditGlobalvarComponent,
  },
  data() {
    return {
      globalvar: "",
      isLoading: false,
      itemDetail: {},
      sortBy: ["id_constant", "KeyConstant", "ValueConstant"],
      headers: [
        { text: "ID", value: "id_constant", filterable: false },
        { text: "Key", value: "KeyConstant" },
        { text: "Value", value: "ValueConstant", filterable: false },
        { text: "Description", value: "Description", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    edit(item) {
      this.itemDetail = item;
      this.$refs.editGlobalvarDialog.toggleDialog();
    },

    fetchHub() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/globalvar`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.items = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
  },
  created() {
    this.fetchHub();
    // this.fetchUserProfile();
  },
};
</script>

<style lang="scss" scoped>
.GlobalvarManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
