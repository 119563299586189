<template>
  <div class="FareCheckInternational">
    <div class="FareCheckInternational-Form">
      <v-form ref="form" v-model="valid">
        <v-container class="ma-0">
          <v-row>
            <v-col xs="2">
              <v-combobox
                label="Receiver Country"
                outlined
                dense
                :rules="rules"
                :items="countryData"
                v-model="country"
                item-text="CountryName"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="2">
              <v-select
                :items="['REGULAR', 'DIRECT', 'GLOBAL']"
                v-model="service"
                item-text="text"
                item-value="value"
                label="Service"
                outlined
                dense
                :rules="rules"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="2">
              <v-text-field
                v-model="weight"
                label="Berat, Kg"
                type="number"
                :rules="rules"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2">
              <v-text-field
                v-model="length"
                label="Panjang"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="width"
                label="Lebar"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="height"
                label="Tinggi"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col xs="2">
              <v-btn
                :loading="isLoading"
                color="orange"
                class="white--text"
                depressed
                block
                @click="check"
                >cek tarif</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div class="FareCheckInternational-Result">
      <hr />
      <div v-if="isResult">
        <br />
        <h3>Origin: {{ origin }}</h3>
        <h3>Destination: {{ destination }}</h3>
        <br />
        <div v-if="listPrice.length > 0">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Service</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Etd</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in listPrice"
                  :key="item.price"
                  @click="toggle(item)"
                >
                  <td>
                    <v-chip class="ma-2" color="green" small text-color="white">
                      {{ item.service }} - {{ item.category }}
                    </v-chip>
                  </td>
                  <td>
                    <strong>{{ convertToRp(item.price) }}</strong>
                  </td>
                  <td>{{ item.etd }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p class="mt-5 font-weight-red" style="color: red">
            * Harga Belum Termasuk PPN
            <br />
            <span v-if="listPrice.length > 0">
              <span style="font-size: 12px" v-if="service == 'DIRECT'"
                >keberangakatan setiap hari selasa - sabtu
              </span>
              <p style="font-size: 12px" v-if="service == 'DIRECT'">
                Penerima paket setiap senin - jumat cut off jam 15:00, Proses
                barang H+1 setelah terima paket, (misal barang pickup senin
                diberangkatkan selasa)
              </p>
            </span>
          </p>

          <v-img style="margin: 0 auto" :src="tos" width="50%"></v-img>
        </div>
        <!-- <v-item-group mandatory v-if="listPrice.length > 0">
          <v-container>
            <v-row>
              <v-col
                v-for="(n, i) in listPrice"
                :key="i"
                md="12"
                sm="12"
                xs="12"
              >
                <v-item v-slot="{ active }">
                  <v-card
                    :color="active ? 'default' : ''"
                    class="d-flex align-center"
                    @click="toggle(n)"
                  >
                    <v-scroll-y-transition>
                      <div
                        class="flex-grow-1 text-center"
                        style="font-size: 14px"
                      >
                        <v-row>
                          <v-col sm="12" md="4" xs="12"
                            >Vendor: {{ i + 1 }} <br />
                            <strong
                              ><i>{{ n.description }}</i></strong
                            >
                          </v-col>
                          <v-col sm="12" md="4" xs="12"
                            >Harga:
                            <strong> {{ convertToRp(n.price) }}</strong></v-col
                          >
                          <v-col sm="12" md="4" xs="12"
                            >Estimasi Pengiriman: {{ n.etd }}</v-col
                          >
                        </v-row>
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group> -->
        <div v-else>
          <v-card class="d-flex align-center" height="50">
            <v-scroll-y-transition>
              <div class="flex-grow-1 text-center font-weight-black">
                <v-row>
                  <v-col v-if="isLoading" cols="12">Loading...</v-col>
                  <v-col v-else cols="12">Data Not Found</v-col>
                </v-row>
              </div>
            </v-scroll-y-transition>
          </v-card>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { formatToRupiah } from "../../../utils/formatter";
import { mapState, mapActions } from "vuex";
import { calculateVolumetricWeight } from "../../../utils/calculationInter";
import tos from "../../../assets/images/tos.jpeg";

export default {
  name: "FareCheckInternational",
  data() {
    return {
      origin: "Indonesia",
      destination: "",
      idOrigin: "",
      idDestination: "",
      weight: 0,
      valid: false,
      price: "",
      isLoading: false,
      isResult: false,
      minEtd: 0,
      maxEtd: 0,
      rules: [(value) => !!value || "Required"],
      length: 0,
      height: 0,
      width: 0,
      villageName: "",
      citySNI: "",
      country: "",
      tos,
      etd: "",
      service: "",
      listPrice: [],
    };
  },
  async mounted() {
    await this.getCountry();
  },
  methods: {
    ...mapActions({
      getCountry: "shipper/getCountry",
    }),
    check() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.getPrice();
      }
    },

    getPrice() {
      this.isLoading = true;
      this.listPrice = [];
      const volumeWeight = calculateVolumetricWeight(
        this.length,
        this.width,
        this.height
      );
      const weight = Number(this.weight);
      const mostWeight = volumeWeight > weight ? volumeWeight : weight;

      axios({
        url: `${baseUrl}/CheckPriceInternational`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          Koli: 1,
          Length: this.length,
          Height: this.height,
          Width: this.width,
          Weight: mostWeight,
          IDCountry: this.country.IDCountry,
        },
      })
        .then((res) => {
          this.isResult = true;
          // this.minEtd = res.data.data.minEtd;
          // this.maxEtd = res.data.data.maxEtd;
          // this.price = formatToRupiah(res.data.data.price);
          this.etd = res.data.data.maxEtd;
          //filter price
          let price = res.data.data.price.filter(
            (el) => el.service == this.service
          );
          this.listPrice = price;
          this.listPrice.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
          this.isLoading = false;
          this.destination = this.country.CountryName;
        })
        .catch(() => {
          this.isResult = true;
          this.price = "No data";
          this.isLoading = false;
        });
    },
    convertToRp(bil) {
      return formatToRupiah(bil);
    },
    toggle(data) {
      console.log(data.price);
    },
  },
  computed: {
    ...mapState({
      countryData: (state) => state.shipper.countryData,
    }),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.FareCheckInternational {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
