<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded color="warning" v-bind="attrs" v-on="on" depressed>
          <v-icon left>mdi-account</v-icon>{{ user }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="item.route"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { dashboardRoute } from "../../constant/routeName";
import {
  getProfileCookie,
  removeProfileCookie,
  removeTokenCookie,
} from "../../utils/cookies";
export default {
  name: "UserMenuComponent",
  data() {
    return {
      items: [
        {
          text: "Profil",
          icon: "mdi-account-circle-outline",
          route: this.navigateProfile,
        },
        {
          text: "Sign out",
          icon: "mdi-logout",
          route: this.logout,
        },
      ],
    };
  },
  computed: {
    user() {
      return getProfileCookie().NamaDepan;
    },
  },
  methods: {
    navigateProfile() {
      if (this.$route.name != dashboardRoute.profile.name) {
        this.$router.push("/dashboard/profile");
      }
    },
    async logout() {
      removeTokenCookie();
      removeProfileCookie();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
