<template>
  <div class="LoginComponent">
    <h3>Register</h3>
    <br />
    <v-form v-model="isValid" @submit="register">
      <v-text-field
        outlined
        dense
        label="Phone"
        type="text"
        :rules="phoneRules"
        v-model="phone"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Password"
        :rules="passwordRules"
        v-model="password"
        :append-icon="valueShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (valueShowPassword = !valueShowPassword)"
        :type="valueShowPassword ? 'password' : 'text'"
      ></v-text-field>

      <v-btn
        block
        depressed
        color="warning"
        :disabled="!isValid"
        type="submit"
        :loading="isLoading"
        >register</v-btn
      >
      <v-btn
        class="mt-2"
        block
        outlined
        color="warning"
        @click="$emit('changeType', 'login')"
        >login
      </v-btn>
    </v-form>

    <div id="register-button"></div>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
// import axios from "axios";
// import { baseUrl } from "../../constant/url";
// import { dashboardRoute } from "@/constant/routeName.js";
// import { setTokenCookie } from "../../utils/cookies";
import firebase from "../../firebase.js";
import { baseUrl } from "../../constant/url.js";
import { formatPhone } from "../../utils/formatter.js";

export default {
  name: "LoginComponent",
  data() {
    return {
      isValid: false,
      phone: "",
      password: "",
      snackbar: false,
      isLoading: false,
      snackbarText: "",
      valueShowPassword: "",
      // emailRules: [
      //   (email) => !!email || "Required",
      //   (email) => /.+@.+/.test(email) || "E-mail must be valid",
      // ],
      phoneRules: [(phone) => !!phone || "Required"],
      passwordRules: [(password) => !!password || "Required"],
    };
  },
  methods: {
    configureRecaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "register-button",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            this.register();
          },
        }
      );
    },
    getOtp() {
      this.configureRecaptcha();

      const phoneNumber = formatPhone(this.phone);
      const appVerifier = window.recaptchaVerifier;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.$emit("changeType", "otp");
          this.$emit("saveData", {
            phone: this.phone,
            password: this.password,
          });
          this.isLoading = false;
          window.confirmationResult = confirmationResult;
          // ...
        })
        .catch((error) => {
          // Error; SMS not sent
          // ...
          this.isLoading = false;
          console.log(error);
        });
    },
    register(e) {
      e.preventDefault();

      this.isLoading = true;
      axios({
        method: "post",
        url: `${baseUrl}/Register`,
        data: {
          Telp: this.phone,
          Password: this.password,
        },
      })
        .then((res) => {
          const { data } = res;
          this.snackbar = true;
          this.snackbarText = data.message.info[0];
          this.isLoading = false;
          // TODO: Disable OTP verification. enable code below to enable otp verification
          // this.getOtp();

          // TODO: Enable verification without OTP, delete code below to enable OTP verification
          this.verifyPhone();
          this.registerWithoutOtp();
        })
        .catch((err) => {
          const { data } = err.response;

          this.snackbarText = data.message.error[0];
          this.snackbar = true;
          this.isLoading = false;
        });
    },
    registerWithoutOtp() {
      this.$emit("changeType", "login");
      this.$emit("saveData", {
        phone: this.phone,
        password: this.password,
      });
      this.isLoading = false;
      window.confirmationResult = true;
    },
    verifyPhone() {
      axios({
        method: "post",
        url: `${baseUrl}/Verify`,
        data: {
          Telp: this.phone,
          Password: this.password,
        },
      })
        .then((res) => {
          const { data } = res;
          this.snackbar = true;
          this.snackbarText = data.message.info[0];
          this.$emit("changeType", "login");
        })
        .catch((err) => {
          const { data } = err.response;
          this.snackbarText = data.message.error[0];
          this.snackbar = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
