<template>
  <div>
    <v-dialog v-model="dialogEditUser" persistent width="600px">
      <v-card>
        <v-card-title> Update User </v-card-title>

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.noKtp"
                    :rules="requiredRule"
                    outlined
                    dense
                    label="No. KTP"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.firstName"
                    :rules="requiredRule"
                    outlined
                    dense
                    label="First Name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.lastName"
                    :rules="requiredRule"
                    outlined
                    dense
                    label="Last Name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.phone"
                    :rules="requiredRule"
                    outlined
                    dense
                    label="No. Handphone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.email"
                    :rules="requiredRule"
                    outlined
                    dense
                    label="Email"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-select
                    outlined
                    dense
                    label="Group User"
                    :rules="requiredRule"
                    item-text="text"
                    item-value="value"
                    :items="userTypeList"
                    v-model="user.idUserType"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-select
                    outlined
                    dense
                    label="Hub Branch"
                    disabled
                    :rules="requiredRule"
                    :items="hubList"
                    v-model="user.idHub"
                    item-text="NamaHub"
                    item-value="IDHub"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kota"
                    disabled
                    :rules="requiredRule"
                    :items="cityList"
                    :value="user.city"
                    @input="setCity"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kelurahan"
                    disabled
                    :rules="requiredRule"
                    :items="villageList"
                    :value="user.village"
                    @input="setVillage"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Kode Pos"
                    disabled
                    v-model="user.postCode"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    v-model="user.address"
                    :rules="requiredRule"
                    disabled
                    outlined
                    dense
                    rows="2"
                    no-resize
                    label="Alamat"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn color="warning" depressed @click="updateUser"> update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { userTypeList } from "../../../constant/user";

import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import { formatPhone } from "../../../utils/formatter";

export default {
  name: "DialogEditUserComponent",
  props: ["user"],
  data() {
    return {
      dialogEditUser: false,
      valid: false,
      hubList: [],
      snackbar: false,
      snackbarText: "",
      requiredRule: [(value) => !!value || "Required"],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogEditUser = !this.dialogEditUser;
    },
    updateUser() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        axios({
          method: "put",
          url: `${baseUrl}/User/${this.user.idUser}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            NamaDepan: this.user.firstName,
            NamaBelakang: this.user.lastName,
            NoKTP: this.user.noKtp,
            Email: this.user.email,
            Telp: formatPhone(this.user.phone),
            Alamat: this.user.address,
            KCX_UserTypeIDUserType: this.user.idUserType,
            KCX_HubIDHub: this.user.idHub,
            KelurahanIDKelurahan: this.user.idVillage,
            KelurahanKodePos: this.user.postCode,
            CityIDCity: this.user.idCity,
            KecamatanIDKecamatan: this.user.idDistrict,
          },
        })
          .then((res) => {
            this.toggleDialog();
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.user.idCity = city.idCity;
        this.user.city = e;
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtname = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtname
      );

      if (village) {
        this.user.idVillage = village.idKelurahan;
        this.user.idDistrict = village.idKecamatan;
        this.user.district = e;
        this.user.postCode = village.kodePos;
      }
    },
  },
  computed: {
    userTypeList() {
      // TODO: Only show manager HO & Operation HO for HO
      const userType = userTypeList.filter((v) => {
        return [1, 3, 4].includes(v.value);
      });
      return userType;
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    villageList() {
      let villageArray = [];

      if (this.user.idCity) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.user.idCity;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
  },
  created() {
    axios({
      method: "get",
      url: `${baseUrl}/Hub`,
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    })
      .then((res) => {
        const hubData = res.data.data.filter(
          (el) => el.IDHub != 0 && el.Status == "ACTIVE"
        );

        this.hubList = hubData;
      })
      .catch(() => {
        this.hubList = [];
      });
  },
};
</script>

<style lang="scss" scoped>
.Form {
  display: flex;
  gap: 20px;
}
</style>
