<template>
  <div class="Logs">
    <div class="Logs-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              outlined
              dense
              v-model="logsSearch"
              label="Search Logs"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="logsSearch"
      :sort-by="sortBy"
      :loading="isLoading"
    >
      <template v-slot:[`item.Response`]="{ item }">
        <v-col cols="auto">
          <v-dialog transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary btn " x-small v-bind="attrs" v-on="on"
                >Response Data</v-btn
              >
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>Response Data</v-toolbar>
                <v-card-text>
                  <span>{{ item.Response }}</span>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </template>
      <template v-slot:[`item.CreatedAt`]="{ item }">
        {{ formatDate(item.CreatedAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import timezone from "moment-timezone";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "Logs",

  data() {
    return {
      logsSearch: "",
      isLoading: false,
      itemDetail: {},
      sortBy: ["Action", "Data"],
      headers: [
        { text: "Action", value: "Action" },
        { text: "Data", value: "Data", filterable: false },
        { text: "Response", value: "Response", filterable: false },
        {
          text: "CreatedAt",
          value: "CreatedAt",
          sortable: false,
          filterable: false,
        },
      ],
      items: [],
    };
  },
  methods: {
    fetchLogs() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/logs`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.items = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    formatDate(date) {
      return timezone(date).local("id").format("DD-MM-YYYY HH:mm:ss");
    },
  },
  created() {
    this.fetchLogs();
  },
};
</script>

<style lang="scss" scoped>
.Logs {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
