<template>
  <div class="ShipperFormComponent">
    <div class="ShipperFormComponent-Header">
      <h3>Shipper Information</h3>
      <hr />
    </div>

    <div class="ShipperFormComponent-Container">
      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0">
            <h4>Nama Pengirim</h4>
          </v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rules"
              :value="shipperName"
              @input="setShipperName"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>No. Handphone</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rules"
              :value="shipperPhone"
              @input="setShipperPhone"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Email</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :value="shipperEmail"
              @input="setShipperEmail"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kota</h4></v-col>

          <v-col cols="8" class="pa-0">
            <!-- <v-text-field :rules="rules" outlined dense></v-text-field>
             -->
            <v-combobox
              outlined
              dense
              :rules="rules"
              :items="cityList"
              :value="city"
              @input="setCity"
            ></v-combobox>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="pa-0">
            <v-checkbox label="Tambah ke address book"></v-checkbox>
          </v-col>
        </v-row> -->
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kelurahan</h4></v-col>

          <v-col cols="8" class="pa-0">
            <!-- <v-text-field :rules="rules" outlined dense></v-text-field>
             -->
            <v-combobox
              outlined
              dense
              :rules="rules"
              :items="villageList"
              :value="village"
              @input="setVillage"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kode Pos</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rules"
              :value="postCode"
              @input="setPostCode"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Alamat</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-textarea
              :rules="rules"
              :value="address"
              @input="setAddress"
              outlined
              rows="3"
              no-resize
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";

export default {
  name: "ShipperFormComponent",
  data() {
    return {
      rules: [(value) => !!value || "Required"],
    };
  },
  computed: {
    shipperName() {
      return this.$store.state.order.shipperData.shipperName;
    },
    shipperPhone() {
      return this.$store.state.order.shipperData.shipperPhone;
    },
    shipperEmail() {
      return this.$store.state.order.shipperData.shipperEmail;
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    city() {
      return this.$store.state.order.shipperData.shipperCity;
    },
    cityId() {
      return this.$store.state.order.shipperData.shipperCityId;
    },
    villageList() {
      let villageArray = [];
      if (this.cityId) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.cityId;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
    village() {
      return this.$store.state.order.shipperData.shipperKelurahan;
    },
    villageId() {
      return this.$store.state.order.shipperData.shipperKelurahanId;
    },
    postCode() {
      return this.$store.state.order.shipperData.shipperPostCode;
    },
    address() {
      return this.$store.state.order.shipperData.shipperAddress;
    },
  },
  created() {},
  watch: {},
  methods: {
    setShipperName(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperName: e });
    },
    setShipperPhone(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperPhone: e });
    },
    setShipperEmail(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperEmail: e });
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.$store.commit("order/SET_SHIPPER", {
          shipperCityId: city.idCity,
          shipperCity: city.city,
        });
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtName = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtName
      );

      if (village) {
        this.$store.commit("order/SET_SHIPPER", {
          shipperKelurahanId: village.idKelurahan,
          shipperKecamatanId: village.idKecamatan,
          shipperKelurahan: village.kelurahan,
          shipperKecamatan: village.kecamatan,
          shipperPostCode: village.kodePos,
        });
      }
    },
    setPostCode(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperPostCode: e });
    },
    setAddress(e) {
      this.$store.commit("order/SET_SHIPPER", { shipperAddress: e });
    },
  },
};
</script>

<style lang="scss" scoped>
.ShipperFormComponent {
  padding: 0 20px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
